import './EditDoctor.scss';
import { CustomButton } from 'components';
import { useState, useEffect } from 'react';
import SimpleForm from 'components/Form/SimpleForm';
import TextInput from 'components/Form/TextInput';
import EmailInput from 'components/Form/EmailInput';
import { useClinicStore, useUserStore } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUploader from 'components/ImageUpload';
import { fetchVetUpdate, fetchVetDelete, fetchClinicAppointments } from 'store/api';
import moment from 'moment';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { toast } from 'react-toastify';

interface VetProfile {
  id: number;
  name: string;
  googleRefreshToken: string;
  email: string;
  phone: string | null;
  about: string | null;
  avatar: string;
  created_at: string;
}

interface Vet {
  id: number;
  title: string;
  associations: string;
  speciality: string;
  location: string;
  experience_from: string;
  rate: string;
  is_fired: boolean;
  created_at: string;
  profile: VetProfile;
  purposes?: string[];
  animals?: string[];
}

export default function EditDoctor() {
  const params = useParams();
  const vetId = Number(params.id);

  const animals = [
    'All',
    'Dogs',
    'Cats',
    'Rabbits',
    'Hamsters',
    'Parrots',
    'Turtles',
    'Snakes',
    'Lizards',
    'Fish',
    'Other'
  ];

  const purposes = [
    'All',
    'Wellness check-up',
    'Vaccinations',
    'Sick visit',
    'Injury or trauma',
    'Surgery consultation',
    'Dental care',
    'Parasite control',
    'Behavioral issues',
    'Nutritional counseling',
    'Other'
  ];

  const navigate = useNavigate();
  const fetchClinicData = useClinicStore((state) => state.fetchClinicData);
  const fetchClinicAppointments = useClinicStore((state) => state.fetchClinicAppointments);
  const { veterinarians, id } = useClinicStore((store) => store.clinic);

  const vetDefault = veterinarians?.find(
    (veterinarian: Vet) => veterinarian?.id === vetId
  ) as unknown as Vet;

  const [about, setAbout] = useState(vetDefault?.profile.about || '');
  const [vet, setVet] = useState<Vet | null>(null);
  const [name, setName] = useState<string>(vetDefault?.profile.name || '');
  const [email, setEmail] = useState<string>(vetDefault?.profile.email || '');
  const [speciality, setSpeciality] = useState<string>(
    vetDefault?.speciality || ''
  );
  const [associations, setAssociations] = useState<string>(
    vetDefault?.associations || ''
  );
  const [title, setTitle] = useState<string>(vetDefault?.title || '');
  const [key, setKey] = useState<number>(0);
  const [experience, setExperience] = useState<string>(
    vetDefault?.experience_from
      ? String(
          Math.ceil(
            moment
              .duration(moment().diff(moment(vetDefault?.experience_from)))
              .asYears()
          )
        )
      : ''
  );
  const [imageURL, setImageURL] = useState<string>(
    vetDefault?.profile?.avatar ||
      'https://www.danchankoinc.com/wp-content/uploads/2017/01/nophoto.png'
  );
  const [selectedAnimals, setSelectedAnimals] = useState<string[]>([]);
  const [selectedPurposes, setSelectedPurposes] = useState<string[]>([]);
  const [animalsError, setAnimalsError] = useState(false);
  const [purposesError, setPurposesError] = useState(false);

  const handleAnimalChange = (event: SelectChangeEvent<string[]>) => {
    const values = event.target.value as string[];
    if (values.includes('All')) {
      setSelectedAnimals(animals.filter((animal) => animal !== 'All'));
    } else {
      setSelectedAnimals(values);
    }
  };

  const handlePurposeChange = (event: SelectChangeEvent<string[]>) => {
    const values = event.target.value as string[];
    if (values.includes('All')) {
      setSelectedPurposes(purposes.filter((purpose) => purpose !== 'All'));
    } else {
      setSelectedPurposes(values);
    }
  };

  const openModal = useUserStore((state) => state.openModal);

  const validateAnimals = () => {
    if (selectedAnimals.length === 0) {
      setAnimalsError(true);
      return true;
    } else {
      setAnimalsError(false);
      return false;
    }
  };

  const validatePurposes = () => {
    if (selectedPurposes.length === 0) {
      setPurposesError(true);
      return true;
    } else {
      setPurposesError(false);
      return false;
    }
  };

  useEffect(() => {
    const currentVet = veterinarians?.find(
      (veterinarian: Vet) => veterinarian?.id === vetId
    ) as unknown as Vet;
    setVet(currentVet);
    if (currentVet) {
      setName(currentVet.profile.name || '');
      setEmail(currentVet.profile.email || '');
      setSpeciality(currentVet.speciality || '');
      setAssociations(currentVet.associations || '');
      setTitle(currentVet.title || '');
      setImageURL(
        currentVet.profile.avatar ||
          'https://www.danchankoinc.com/wp-content/uploads/2017/01/nophoto.png'
      );
      setExperience(
        currentVet.experience_from
          ? String(
              Math.ceil(
                moment
                  .duration(moment().diff(moment(currentVet.experience_from)))
                  .asYears()
              )
            )
          : ''
      );
      setSelectedAnimals(currentVet?.animals || []);
      setSelectedPurposes(currentVet?.purposes || []);
      setKey((prevKey) => prevKey + 1);
    }
  }, [vetId, veterinarians]);

  const handleSubmit = async () => {
    const isAnimalsError = validateAnimals();
    const isPurposesError = validatePurposes();

    if (
      isAnimalsError ||
      isPurposesError ||
      selectedAnimals.length === 0 ||
      selectedPurposes.length === 0
    ) {
      return;
    }

    const vetData = {
      vet_id: vetId,
      email,
      speciality,
      name,
      title,
      experience: moment().subtract(Number(experience), 'years').toISOString(),
      associations,
      avatar: imageURL,
      about: about,
      animals: selectedAnimals,
      purposes: selectedPurposes,
    };

    try {
      await fetchVetUpdate(vetData);
      await fetchClinicData();
      await fetchClinicAppointments();
      openModal(
        'success',
        'Congratulations!',
        'Your doctor info successfully saved!',
        null
      );
      navigate('/overview');
    } catch (error) {
      console.error('Failed to update clinic info: ', error);
      toast.error('Some error occured, please check your data', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        toastId: 'uniqueId3',
      });
    }
  };

  const handleDelete = async (vetId: number) => {
    try {
      const vetData = {
        vet_id: vetId,
      };
      // await fetchVetDelete(vetData);
      openModal(
        'warning',
        'Are you sure?',
        'You want to remove this doctor?',
        'deleteVet',
        vetData
      );
      navigate('/overview');
    } catch (error) {
      console.error('Failed to update clinic info: ', error);
    }
  };

  return (
    <div className='vet-edit'>
      <div className='vet-edit__top-block'>
        <div className='vet-edit__top-block__left'>
          <ImageUploader
            image={imageURL}
            setImageURL={setImageURL}
            className='create-doctor-upload'
          />
        </div>
        <div className='vet-edit__top-block__right'>
          <div className='vet-edit__top-block__right__name'>
            {vetDefault?.profile.name}
          </div>
          <div className='vet-edit__top-block__right__title-expertise'>
            {vetDefault?.title + ' ' + vetDefault?.speciality}
          </div>
          <div className='vet-edit__top-block__right__email'>{email}</div>
        </div>
      </div>

      <SimpleForm
        submitHandler={handleSubmit}
        key={vetId}
        title="Edit doctor's account"
        bordered={'none'}
      >
        <div className='simple-form__header'>
          <div className='simple-form__title'>{'Edit doctor`s account'}</div>
          <div className='simple-form__top-button-container'>
            <CustomButton
              text='Edit'
              classes={`
              custom-button__orange 
              custom-button__thin 
              custom-button__no-margin 
              custom-button__no-hover`}
              variant='outline'
              type='submit'
            />

            <CustomButton
              text='Remove'
              classes={`
              custom-button__red
              custom-button__thin 
              custom-button__no-margin 
              `}
              variant='ghost'
              onClick={(e) => {
                e.preventDefault();
                handleDelete(vetId);
              }}
            />
          </div>
        </div>
        <hr />
        <div className='vet-registration__grid' key={key}>
          <div className='vet-registration__item'>
            <TextInput
              name='Full name'
              setText={setName}
              isEmpty={false}
              value={name}
            />
          </div>
          <div className='vet-registration__item'>
            <EmailInput
              label='Email'
              setEmail={setEmail}
              isEmpty={false}
              value={email}
            />
          </div>
          <div className='vet-registration__item'>
            <TextInput
              name='Professional designation or title'
              setText={setTitle}
              isEmpty={false}
              value={title}
            />
          </div>
          <div className='vet-registration__item'>
            <TextInput
              name='Specific specialization or area of expertise'
              setText={setSpeciality}
              isEmpty={false}
              value={speciality}
            />
          </div>
          <div className='vet-registration__item'>
            <TextInput
              name='Membership of veterinary associations or organization'
              setText={setAssociations}
              isEmpty={false}
              value={associations}
            />
          </div>
          <div className='vet-registration__item'>
            <TextInput
              name='Years of experience'
              setText={setExperience}
              isEmpty={false}
              isExperience={true}
              value={experience}
            />
          </div>
          <div className='vet-registration__item'>
            <FormControl variant='filled' className='select-control' fullWidth>
              <InputLabel id='animals-select-label'>Animals</InputLabel>
              <Select
                labelId='animals-select-label'
                id='animals-select'
                multiple
                value={selectedAnimals}
                onBlur={validateAnimals}
                onChange={handleAnimalChange}
                error={animalsError}
              >
                {animals.map((animal, index) => (
                  <MenuItem key={index} value={animal}>
                    {animal}
                  </MenuItem>
                ))}
              </Select>
              {animalsError && selectedAnimals && (
                <div className='select-error'>
                  Please select at least one animal.
                </div>
              )}
            </FormControl>
          </div>
          <div className='vet-registration__item'>
            <FormControl variant='filled' className='select-control' fullWidth>
              <InputLabel id='purposes-select-label'>Purposes</InputLabel>
              <Select
                labelId='purposes-select-label'
                error={purposesError}
                id='purposes-select'
                multiple
                value={selectedPurposes}
                onBlur={validatePurposes}
                onChange={handlePurposeChange}
              >
                {purposes.map((purpose, index) => (
                  <MenuItem key={index} value={purpose}>
                    {purpose}
                  </MenuItem>
                ))}
              </Select>
              {purposesError && selectedPurposes && (
                <div className='select-error'>
                  Please select at least one purpose.
                </div>
              )}
            </FormControl>
          </div>
        </div>{' '}
        <div className='vet-registration__item'>
          <label className='vet-registration__about-label'>About Veterinarian</label>
          <textarea
            className='input notes'
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
        </div>
      </SimpleForm>
    </div>
  );
}
