import {Form, PrimaryButton} from 'components';
import {Link, useNavigate} from 'react-router-dom';
import Email from 'assets/images/email.png';
import EmailInput from 'components/Form/EmailInput';
import SubmitButton from 'components/Form/SubmitButton';
import {useAuthStore} from 'store';
import {useState} from 'react';

export default function PasswordRecovery() {
  const navigate = useNavigate();

  const fetchPasswordRecovery = useAuthStore((state) => state.fetchPasswordRecovery);

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!email) return setError(true);
    fetchPasswordRecovery(email);
    setSent(true);
  };

  return (
    sent
      ? <div className="password-recovery">
        <img className="password-recovery__image" src={Email} alt=""/>
        <h3 className="password-recovery__title">Check your mail </h3>
        <p className="password-recovery__subtitle">
            We’ve sent a password change instruction to your mail
        </p>
        <PrimaryButton
          className="password-recovery__button"
          onClick={() => navigate('/')}
          text="Home Page"
        />
      </div>
      : <Form
        submitHandler={handleSubmit}
        title="Reset password"
      >
        <EmailInput setEmail={setEmail} auth={true} isEmpty={error}/>
        <SubmitButton text="Send" loader={false}/>
        <p className="form__tooltip">
          Don’t have an account?
          <Link to="/sign-up">Sign Up</Link>
        </p>
      </Form>
  );
}
