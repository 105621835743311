import { 
  Box, 
  Button, 
  Divider, 
  styled, 
  TextareaAutosize, 
  TextField, 
  Typography 
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  createPetRecord,
  deletePetRecord, 
  fetchPetProfile, 
  fetchPetRecords, 
  updatePetOwner, 
  updatePetProfile, 
  updatePetRecord
} from 'store/api';
import './PetProfile.scss';
import { ReactComponent as Add } from 'assets/images/plus.svg';
import InputMask from 'react-input-mask';

import dog from '../../assets/images/dog.png';
import cat from '../../assets/images/cat.png';
import lizard from '../../assets/images/lizard.png';
import rabbit from '../../assets/images/rabbit.png';
import rooster from '../../assets/images/parrot.png';
import snake from '../../assets/images/snake.png';
import fish from '../../assets/images/fish.png';
import turtle from '../../assets/images/turtle.png';
import hamster from '../../assets/images/hamster.png';
import defaultImage from '../../assets/images/default.png';
import { PetProfileInput } from 'components/PetProfile/ProfileInput';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { EditRecordModal } from 'components/Modals/EditRecordModal';
import { EditOwnerModal } from 'components/Modals/EditOwnerModal';

const imageComponents = [
  { name: 'dog', source: dog },
  { name: 'cat', source: cat },
  { name: 'lizard', source: lizard },
  { name: 'rabbit', source: rabbit },
  { name: 'rooster', source: rooster },
  { name: 'snake', source: snake },
  { name: 'fish', source: fish },
  { name: 'turtle', source: turtle },
  { name: 'hamster', source: hamster },
  { name: 'no avatar', source: defaultImage },
];

const getAvatarSource = (avatar?: string) => {
  const image = imageComponents.find((image) => image.name === avatar);
  return image ? image.source : null;
};

export type Pet = {
  id: number;
  color: string;
  breed: string;
  name: string;
  weight: number;
  birthday: string;
  notes: string;
  avatar: string;
  created_at: string;
};

export type PetDB = {
  id: number;
  is_archived: boolean;
  default_logo: string;
  created_at: string;
  profile: ProfilePet;
  microchip: string;
  microchipId: string;
};

export type PetOwner = {
  id: number;
  created_at: string;
  profile: {
    id: number;
    about: string;
    addres: string;
    avatar: string;
    created_at: string;
    email: string;
    name: string;
    phone: string;
  }
};

export type ProfilePet = {  
  id: number;
  color: string;
  breed: string;
  name: string;
  weight: number;
  birthday: string;
  notes: string;
  avatar: string;
  is_archived: boolean;
  default_logo: string;
  created_at: string;
  profile: Pet;
  microchipId: string;
  owners: PetOwner[];
};

const ProfileBlock = styled(Box)`
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  padding: 28px;
  width: 70%;
  max-width: 928px;
`;

export type PetRecord = {
  id: number;
  diagnosis: string;
  treatment: string;
  note: string;
  created_at: string;
};

function getOwnersNames(owners: PetOwner[]) {
  return `
  ${owners.length > 1 ? 'owners' : 'owner'} 
  ${owners.map(owner => owner.profile.name).join(',')}
  `;
}

export function formatDate(inputDate: string) {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

function calculateAge(birthdate: string) {
  // Parse the birthdate string into a Date object
  const birthdateDate = new Date(birthdate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in years and months
  const yearsDiff = currentDate.getFullYear() - birthdateDate.getFullYear();
  const monthsDiff = currentDate.getMonth() - birthdateDate.getMonth();

  // Calculate the number of days remaining in the birth month
  const daysInBirthMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  ).getDate();
  const daysDiff = currentDate.getDate() - birthdateDate.getDate();

  // Calculate the age in years and months
  let ageYears = yearsDiff;
  let ageMonths = monthsDiff;

  if (daysDiff < 0) {
    // Adjust if the birth day hasn't occurred yet this month
    ageMonths--;
    if (ageMonths < 0) {
      // If negative months, adjust years and months
      ageYears--;
      ageMonths += 12;
    }
  }

  // Format the age string
  let ageString = '';
  if (ageYears > 0) {
    ageString += `${ageYears} year${ageYears === 1 ? '' : 's'}`;
  }
  if (ageMonths > 0) {
    ageString +=
      ageString.length > 0 
        ? ` ${ageMonths} month${ageMonths === 1 ? '' : 's'}` 
        : `${ageMonths} month${ageMonths === 1 ? '' : 's'}`;
  }

  return ageString.trim();
}

const mokRecords = [
  {
    id: 0,
    diagnosis: 'diagnosis',
    treatment: 'treatment',
    note: 'note',
    created_at: '2022-01-01'
  }
];

export function PetProfile() {
  const { petId } = useParams();
  const [pet, setPet] = useState<ProfilePet>();
  const [records, setRecords] = useState<PetRecord[]>([]);
  const [petEdit, setPetEdit] = useState(false);
  const [ownersEdit, setOwnersEdit] = useState(false);
  const [newRecordEdit, setNewRecordEdit] = useState(false);

  const [petName, setPetName] = useState('');
  const [petColor, setPetColor] = useState('');
  const [petBreed, setPetBreed] = useState('');
  const [petWeight, setPetWeight] = useState('');
  const [petMicrochip, setPetMicrochip] = useState('');
  const [petBirthday, setPetBirthday] = useState('');
  const [petNote, setPetNote] = useState('');

  const [ownerName, setOwnerName] = useState('');
  const [ownerPhone, setOwnerPhone] = useState('');
  const [ownerMail, setOwnerMail] = useState('');

  const [treatment, setTreatment] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [recordNotes, setRecordNotes] = useState('');

  const [editRecord, setEditRecord] = useState<number | null>(null);
  const [editOwner, setEditOwner] = useState<number | null>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        if (petId) {
          const pet = await fetchPetProfile(+petId);
          setPet(pet);
          setPetName(pet.profile.name);
          setPetColor(pet.profile.color);
          setPetBreed(pet.profile.breed);
          setPetWeight(pet.profile.weight.toString());
          setPetMicrochip(pet.microchipId.toString());
          setPetBirthday(formatDate(pet.profile.birthday));
          setPetNote(pet.profile.notes);

          const petRecords = await fetchPetRecords(pet.id);
          setRecords(petRecords);
        }
      } catch (err) {
        console.log('get pet details data error');
      }
    };

    getData();
  }, [petId]);

  const handleEditPetInfo = async () => {
    try {
      setPetEdit(false);

      const newData = {
        name: petName ? petName : pet?.profile.name,
        color: petColor ? petColor : pet?.profile.color,
        breed: petBreed ? petBreed : pet?.profile.breed,
        birthday: petBirthday ? new Date(petBirthday) : pet?.profile.birthday,
        weight: petWeight ? +petWeight : pet?.profile.weight,
        microchipId: petMicrochip ? petMicrochip : pet?.microchipId,
        notes: petNote ? petNote : pet?.profile.notes
      };
  
      await updatePetProfile({
        petId: pet?.id,
        ...newData
      });
    } catch {
      toast.error('Unable to update pet information');
    } finally {
      if (petId) {
        const newPetInfo = await fetchPetProfile(+petId);
          setPet(newPetInfo);
          setPetName(newPetInfo.profile.name);
          setPetColor(newPetInfo.profile.color);
          setPetBreed(newPetInfo.profile.breed);
          setPetWeight(newPetInfo.profile.weight.toString());
          setPetMicrochip(newPetInfo.microchipId.toString());
          setPetBirthday(formatDate(newPetInfo.profile.birthday));
          setPetNote(newPetInfo.profile.notes);
      }
    }
  };
  
  const handleAddOwner = async () => {
    try {
      setOwnersEdit(false);
      if (!ownerName.trim() || !ownerMail.trim() || !ownerPhone.trim()) {
        toast.warn('All fileds ar required');
        return;
      }

      const mailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

      if (!mailPattern.test(ownerMail)) {
        toast.warn('Wrong email format');
        return;
      }

      const newData = {
        petId: pet?.id,
        name: pet?.profile.name,
        owners: [
          {
            name: ownerName,
            email: ownerMail,
            phone: ownerPhone,
          }
        ]
      };

      await updatePetProfile(newData);
    } catch {
      toast.error('Unable to add new owner');
    } finally {
      setOwnerMail('');
      setOwnerName('');
      setOwnerPhone('');
      if (petId) {
        const newPetInfo = await fetchPetProfile(+petId);
          setPet(newPetInfo);
          setPetName(newPetInfo.profile.name);
          setPetColor(newPetInfo.profile.color);
          setPetBreed(newPetInfo.profile.breed);
          setPetWeight(newPetInfo.profile.weight.toString());
          setPetMicrochip(newPetInfo.microchipId.toString());
          setPetBirthday(formatDate(newPetInfo.profile.birthday));
          setPetNote(newPetInfo.profile.notes);
      }
    }
  };

  const handleEditOwner = async (name: string, email: string, phone: string, oldEmail: string) => {
    try {
      if (!name.trim() || !email.trim() || !phone.trim()) {
        toast.warn('All fields are required');
      }

      const mailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

      if (!mailPattern.test(email)) {
        toast.warn('Wrong email format');
        return;
      }

      const newData = {
        petId: pet?.id,
        oldEmail,
        owner: {
          name,
          email,
          phone,
        }
      };

      await updatePetOwner(newData);
    } catch {
      toast.error('Can not update the owner');
    } finally {
      if (petId) {
        const newPetInfo = await fetchPetProfile(+petId);
        setPet(newPetInfo);
        setPetName(newPetInfo.profile.name);
        setPetColor(newPetInfo.profile.color);
        setPetBreed(newPetInfo.profile.breed);
        setPetWeight(newPetInfo.profile.weight.toString());
        setPetMicrochip(newPetInfo.microchipId.toString());
        setPetBirthday(formatDate(newPetInfo.profile.birthday));
        setPetNote(newPetInfo.profile.notes);
      }
    }
  };

  const handleAddRecord = async () => {
    try {
      setNewRecordEdit(false);
      if (!treatment || !diagnosis || !recordNotes) {
        toast.warn('All fields are required');
        return;
      }

      const data = {
        petId: pet?.id,
        treatment,
        diagnosis,
        note: recordNotes
      };

      await createPetRecord(data);

      if (pet) {
        const petRecords = await fetchPetRecords(pet?.id);
        setRecords(petRecords);
      }
    } catch {
      toast.error('Unable to create new medical record');
    } finally {
      setDiagnosis('');
      setTreatment('');
      setRecordNotes('');
    }
  };

  const handleDeleteRecord = async (recordId: number) => {
    try {
      await deletePetRecord(recordId);
      if (pet) {
        const records = await fetchPetRecords(pet.id);
        setRecords(records);
      }
    } catch {
      toast.error('Can not delete the record');
    }
  };

  const handleUpdateRecord = async (
    recordId: number, 
    newTreatment: string, 
    newDiagnosis: string, 
    newRecordNotes: string
  ) => {
    try {
      if (!newTreatment.trim() || !newDiagnosis.trim() || !newRecordNotes.trim()) {
        toast.warn('All fields are required');
        return;
      }
      const newData = {
        treatment: newTreatment,
        diagnosis: newDiagnosis,
        note: newRecordNotes,
      };

      await updatePetRecord(recordId, newData);

      if (pet) {
        const records = await fetchPetRecords(pet.id);
        setRecords(records);
      }
    } catch {
      toast.error('Can not update the record');
    } finally {
      setEditRecord(null);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        width: '100%'
      }}
    >
      {editRecord && <EditRecordModal
        isOpened={!!editRecord}
        onClose={() => setEditRecord(null)}
        onDelete={handleDeleteRecord}
        onSave={handleUpdateRecord}
        record={records.find(record => record.id === editRecord) as PetRecord}
      />}

      {editOwner && <EditOwnerModal
        isOpened={!!editOwner}
        onClose={() => setEditOwner(null)}
        onSave={handleEditOwner}
        owner={pet?.owners.find(owner => owner.id === editOwner) as PetOwner}
      />}
      <ProfileBlock
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '24px'
        }}
      >
        <img className='pet-profile__avatar' src={getAvatarSource(pet?.profile.avatar)
          || defaultImage} />

        <Box
          sx={{
            marginBottom: '10px'
          }}
        >
          <Typography
            sx={{
              color: '#2F5EA0',
              fontSize: '20px',
              fontWeight: 600,
              letterSpacing: 0.4,
              fontFamily: 'Outfit'
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 600,
                color: '#08182F',
                letterSpacing: 0.4,
                display: 'inline',
                marginRight: '4px',
                fontFamily: 'Outfit'
              }}
            >
              {pet ? pet.profile.breed : ''}
            </Typography>

            {pet ? pet.profile.name : ''}
          </Typography>

          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#979696',
              marginBottom: '16px',
              fontFamily: 'Outfit'
            }}
          >
            {pet ? `${calculateAge(pet?.profile.birthday)}, ${getOwnersNames(pet.owners)}`: ''}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '10px'
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                color: '#FF974D',
                fontFamily: 'Outfit'
              }}
            >
              {pet ? pet.profile.color : ''}
            </Typography>

            <Typography
              sx={{
                color: '#979696',
                fontSize: '14px',
                fontWeight: 600,
                fontFamily: 'Outfit'
              }}
            >
              #{pet ? pet.profile.id : ''}
            </Typography>
          </Box>
        </Box>
      </ProfileBlock>

      <ProfileBlock
        sx={{
          paddingY: '32px'
        }}
      >
        <Box
          sx={{
            borderRadius: '12px',
            border: '1px dashed #979696',
            backgroundColor: '#FAFBFF',
            padding: '32px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 600,
                color: '#08182F',
                fontFamily: 'Outfit'
              }}
            >
              Pet’s personal info
            </Typography>

            <Button
              sx={{
                fontSize: '13px',
                fontWeight: 500,
                color: '#FF974D',
                fontFamily: 'Outfit',
                textTransform: 'none',
                borderColor: '#FF974D',
                borderRadius: '22px',
                width: '100px',
                height: '31px'
              }}
              variant='outlined'
              onClick={() => {
                if (petEdit) {
                  handleEditPetInfo();
                  return;
                } 
                setPetEdit(true);
              }}
            >
              {petEdit ? 'Save' : 'Edit'}
            </Button>
          </Box>

          <Divider />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <PetProfileInput
              label='Name'
              value={petName}
              enabled={petEdit}
              setValue={setPetName}
            />

            <PetProfileInput
              label='Color'
              value={petColor}
              enabled={petEdit}
              setValue={setPetColor}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <PetProfileInput
              label='Breed'
              value={petBreed}
              enabled={petEdit}
              setValue={setPetBreed}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px'
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#FF974D',
                  fontFamily: 'Outfit'
                }}
              >
                Date of birth
              </Typography>

              <InputMask
                placeholder='DD/MM/YYYY'
                mask="99/99/9999"
                value={petBirthday}
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => setPetBirthday(e.target.value)
                }
                className='pet-profile__pet-birthday'
                style={{
                  pointerEvents: petEdit ? 'all' : 'none'
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <PetProfileInput
              label='Weight'
              value={petWeight}
              enabled={petEdit}
              setValue={setPetWeight}
            />

            <PetProfileInput
              label='Microchip ID'
              value={petMicrochip}
              enabled={petEdit}
              setValue={setPetMicrochip}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#FF974D',
                fontFamily: 'Outfit'
              }}
            >
              Note
            </Typography>

            <TextareaAutosize
              value={petNote}
              placeholder='Note'
              minRows={6}
              readOnly={!petEdit}
              onChange={(e) => setPetNote(e.target.value)}
              className='pet-profile__notes'
            />
          </Box>

          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              color: '#08182F',
              fontFamily: 'Outfit'
            }}
          >
            Owner’s info
          </Typography>

          <Divider />

          {pet && pet.owners.map(owner => (
            <>
              <Box
                key={v4()}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >

                <PetProfileInput
                  label='Owner’s Name'
                  value={owner.profile.name}
                />

                <PetProfileInput
                  label='Owner’s Phone'
                  value={owner.profile.phone}
                />
              </Box>

              <PetProfileInput
                label='Owner’s Email'
                value={owner.profile.email}
              />

              <Button
                sx={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: '#FF974D',
                  fontFamily: 'Outfit',
                  textTransform: 'none',
                  borderColor: '#FF974D',
                  borderRadius: '22px',
                  width: '100px',
                  height: '31px'
                }}
                variant='outlined'
                onClick={() => setEditOwner(owner.id)}
              >
                Edit
              </Button>

              <Divider />
            </>
          ))}

          {ownersEdit && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <PetProfileInput
                  label='Owner’s Name'
                  value={ownerName}
                  enabled={ownersEdit}
                  setValue={setOwnerName}
                />

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#FF974D',
                      fontFamily: 'Outfit'
                    }}
                  >
                    Owner’s Phone
                  </Typography>

                  <InputMask
                    placeholder='+00 (000) 000 00 00'
                    mask="+99 (999) 999 99 99"
                    value={ownerPhone}
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) => setOwnerPhone(e.target.value)
                    }
                    className='pet-profile__pet-birthday'
                  />
                </Box>
              </Box>

              <PetProfileInput
                label='Owner’s Email'
                value={ownerMail}
                enabled={ownersEdit}
                setValue={setOwnerMail}
              />

              <Button
                sx={{
                  fontSize: '13px',
                  fontWeight: 500,
                  color: '#FF974D',
                  fontFamily: 'Outfit',
                  textTransform: 'none',
                  borderColor: '#FF974D',
                  borderRadius: '22px',
                  width: '100px',
                  height: '31px'
                }}
                variant='outlined'
                onClick={() => handleAddOwner()}
              >
                Save
              </Button>
            </>
          )}

          <Button
            sx={{
              width: 'max-content',
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: 500,
              color: '#2F5EA0',
              fontFamily: 'Outfit'
            }}
            disabled={ownersEdit}
            startIcon={<Add className='pet-profile__plus-icon' />}
            onClick={() => setOwnersEdit(true)}
          >
            Add another owner
          </Button>
        </Box>
      </ProfileBlock>

      <ProfileBlock
        sx={{
          gap: '24px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            color: '08182F',
            fontFamily: 'Outfit'
          }}
        >
          Medical records
        </Typography>

        <Button
          sx={{
            fontSize: '13px',
            fontWeight: 500,
            color: '#FFF',
            fontFamily: 'Outfit',
            textTransform: 'none',
            borderColor: '#FF974D',
            borderRadius: '22px',
            backgroundColor: '#FF974D',
            height: '31px',
            width: 'max-content'
          }}
          variant='contained'
          onClick={() => setNewRecordEdit(true)}
          disabled={newRecordEdit}
        >
          Create new
        </Button>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
          }}
        >
          {newRecordEdit && (
            <Box
              sx={{
                borderRadius: '12px',
                border: '1px dashed #979696',
                backgroundColor: '#FAFBFF',
                padding: '32px',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px'
              }}
            >

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <PetProfileInput
                  label='Treatment'
                  value={treatment}
                  enabled={newRecordEdit}
                  setValue={setTreatment}
                />

                <PetProfileInput
                  label='Diagnosis'
                  value={diagnosis}
                  enabled={newRecordEdit}
                  setValue={setDiagnosis}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#FF974D',
                    fontFamily: 'Outfit'
                  }}
                >
                  Note
                </Typography>

                <TextareaAutosize 
                  value={recordNotes}
                  minRows={6}
                  onChange={(e) => setRecordNotes(e.target.value)}
                  className='pet-profile__notes'
                />

                <Button
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: '#FF974D',
                    fontFamily: 'Outfit',
                    textTransform: 'none',
                    borderColor: '#FF974D',
                    borderRadius: '22px',
                    width: '100px',
                    height: '31px'
                  }}
                  variant='outlined'
                  onClick={() => handleAddRecord()}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
          {records && records.map(record => 
            <Box
              key={v4()}
              sx={{
                borderRadius: '12px',
                border: '1px dashed #979696',
                backgroundColor: '#FAFBFF',
                padding: '32px',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#08182F',
                    fontFamily: 'Outfit',
                  }}
                >
                  {formatDate(record.created_at)}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  <Button
                    sx={{
                      fontSize: '13px',
                      fontWeight: 500,
                      color: '#FF974D',
                      fontFamily: 'Outfit',
                      textTransform: 'none',
                      borderColor: '#FF974D',
                      borderRadius: '22px',
                      width: '100px',
                      height: '31px'
                    }}
                    variant='outlined'
                    onClick={() => {
                      setEditRecord(record.id);
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    sx={{
                      fontSize: '13px',
                      fontWeight: 500,
                      color: '#F55555',
                      fontFamily: 'Outfit',
                      textTransform: 'none',
                      width: '100px',
                      height: '31px'
                    }}
                    onClick={() => handleDeleteRecord(record.id)}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>

              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <PetProfileInput
                  label='Treatment'
                  value={record.treatment}
                />

                <PetProfileInput
                  label='Diagnosis'
                  value={record.diagnosis}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#FF974D',
                    fontFamily: 'Outfit'
                  }}
                >
                  Note
                </Typography>

                <TextareaAutosize 
                  value={record.note}
                  minRows={6}
                  readOnly
                  className='pet-profile__notes'
                />
              </Box>
            </Box>
          )}

          {records.length <= 0 && (
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 500,
                color: '#08182F',
                fontFamily: 'Outfit',
                textAlign: 'center'
              }}
            >
              No records yet.
            </Typography>
          )}
        </Box>
      </ProfileBlock>
    </Box>
  );
}
