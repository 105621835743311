import { error, regex } from 'data';
import { useEffect, useState } from 'react';
import Input from '../Input';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { passRequirements } from 'data/passRequirements';
import { v4 } from 'uuid';

interface Props {
  confirm?: boolean;
  password: string;
  confirmPassword?: string;
  // eslint-disable-next-line no-unused-vars
  setPassword: (password: string) => void;
  // eslint-disable-next-line no-unused-vars
  setPasswordConfirm?: (passwordConfirm: string) => void;
  isEmpty: boolean;
  isEmptyConfirm?: boolean;
  noStrong?: boolean;
  auth?: boolean;
}

export default function PasswordInput(props: Props) {
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (props.isEmpty || props.isEmptyConfirm) {
      if (props.isEmpty) setPasswordError(error.emptyPassword);
      if (props.isEmptyConfirm) return setConfirmPasswordError(error.emptyConfirmPassword);
      if (props.password !== props.confirmPassword)
        return setConfirmPasswordError(error.differentPasswords);
    }
  }, [props.isEmpty, props.password, props.confirmPassword, props.isEmptyConfirm]);

  const handlerPasswordBlur = (password: string) => {
    const passwordIsCorrect = password.match(regex.password);
    if (!password) {
      setPasswordError(error.emptyPassword);
      setIsPasswordInvalid(false); 
    } else if (!passwordIsCorrect && props.confirm) {
      setPasswordError(error.weakPassword);
      setIsPasswordInvalid(true); 
    } else {
      setPasswordError(null);
      props.setPassword(password);
      props.setPassword(password);
      setIsPasswordInvalid(false); 
    }
  };
  const handleConfirmPasswordBlur = (confirmPassword: string) => {
    const confirmPasswordIsCorrect = props.password === confirmPassword;
    if (!confirmPassword) {
      setConfirmPasswordError(error.emptyConfirmPassword);
    } else if (!confirmPasswordIsCorrect) {
      setConfirmPasswordError(error.differentPasswords);
    } else {
      setConfirmPasswordError(null);
      props.setPasswordConfirm && props.setPasswordConfirm(confirmPassword);
    }
  };

  return (
    <Box sx={{ position: 'relative', marginTop: '18px' }}>
      <Input
        className={`form__input 
        ${props.auth ? ' auth-input' : ''}
        `}
        type='password'
        placeholder='Password'
        error={passwordError}
        value={props.password}
        setValue={props.setPassword}
        onBlur={handlerPasswordBlur}
      />
      {isPasswordInvalid && (
        <Box
          sx={{
            backgroundColor: '#08182F',
            padding: '10px',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            position: 'absolute',
            top: '-35%',
            right: '-57%'
          }}
        >
          <Box 
            sx={{
              clipPath: 'polygon(100% 10%, 30% 50%, 100% 90%)',
              backgroundColor: '#08182F',
              width: '19px',
              height: '44px',
              position: 'absolute',
              left: '-10%',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          />

          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 600,
              color: '#FFF',
              textAlign: 'center'
            }}
          >
            Password requirements:
          </Typography>

          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0px',
              padding: 0,
              width: '160px'
            }}
          >
            {passRequirements.map(require => (
              <ListItem
                key={v4()}
                sx={{
                  padding: 0,
                  display: 'flex',
                  gap: '4px',
                }}
              >
                <ListItemIcon
                  sx={{
                    color: '#E9E9E9',
                    minWidth: 'max-content',
                    fontSize: '12px',
                    paddingX: '4px'
                  }}
                >
                  •
                </ListItemIcon>

                <ListItemText
                  sx={{
                    color: '#E9E9E9',
                    margin: 0,
                    '& span': {
                      fontSize: '12px'
                    }
                  }}
                >
                  {require}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {props.confirm && (
        <Input
          className={`form__input 
            ${props.auth ? ' auth-input' : ''}
            `}
          type='password'
          placeholder='Confirm password'
          error={confirmPasswordError}
          value={props.confirmPassword || ''}
          setValue={props.setPasswordConfirm}
          onBlur={handleConfirmPasswordBlur}
        />
      )}
    </Box>
  );
}
