import React, { useEffect, useState } from 'react';
import './CreatePet.scss';
import SuccessModal from './SuccessModal';
import { imageComponents } from '../../data';
import { CreatePetProfile, fetchGetVetParams } from 'store/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type Owner = {
  name: string;
  email: string;
  phone: string;
};


export default function CreatePet() {
  const [images, setImages] = useState(imageComponents);
  const [selectedImage, setSelectedImage] = useState('');
  const [ownersCount, setOwnersCount] = useState(1);
  const [owners, setOwners] = useState<Owner[]>([
    {
      name: '',
      email: '',
      phone: '',
    },
  ]);
  const [animals, setAnimals] = useState<{ id: number; name: string }[]>([
    { id: 0, name: '' },
  ]);
  const [name, setName] = useState('');
  const [species, setSpecies] = useState('Dogs');
  const [breed, setBreed] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [microchipId, setMicrochipId] = useState('');
  const [note, setNote] = useState('');
  const [birthValid, setBirthValid] = useState(true);
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [invalidOwnerIndices, setInvalidOwnerIndices] = useState<number[]>([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const isEmailValid = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isPhoneValid = (phone: string) => {
    const phonePattern = /^[\d-]+$/;
    return phonePattern.test(phone);
  };

  const isOwnersValid = () => {
    const newInvalidIndices: number[] = [];
    owners.forEach((owner, index) => {
      if (
        owner.name.trim() === '' ||
        owner.email.trim() === '' ||
        owner.phone.trim() === '' ||
        !isEmailValid(owner.email.trim()) ||
        !isPhoneValid(owner.phone.trim())
      ) {
        newInvalidIndices.push(index);
      }

      if (owner.name.trim() === '') {
        setEmptyFields((prevFields) => [...prevFields, `ownerName_${index}`]);
      }

      if (!isEmailValid(owner.email.trim())) {
        setEmptyFields((prevFields) => [...prevFields, `ownerEmail_${index}`]);
      }
      if (!isPhoneValid(owner.phone.trim())) {
        setEmptyFields((prevFields) => [...prevFields, `ownerPhone_${index}`]);
      }
    });

    setInvalidOwnerIndices(newInvalidIndices);

    return newInvalidIndices.length === 0;
  };

  const isDateOfBirthValid = (date: string) => {
    if (!/^\d{2}-\d{2}-\d{4}$/.test(date)) {
      return false;
    }

    const birthDate = new Date(date);
    const today = new Date();

    if (Number.isNaN(birthDate.getTime())) {
      return false;
    }

    if (birthDate > today) {
      return false;
    }

    return true;
  };

  const handleOwnerChange = (
    index: number,
    field: keyof Owner,
    value: string
  ) => {
    setOwners((prevOwners) => {
      const updatedOwners = [...prevOwners];
      updatedOwners[index] = { ...updatedOwners[index], [field]: value };
      return updatedOwners;
    });
  };

  useEffect(() => {
    const fetchPets = async () => {
      const data = await fetchGetVetParams();
      setAnimals(data.data.animals);
    };

    fetchPets();
  }, []);

  const handleImageSelect = (imageName: string) => {
    setSelectedImage(imageName);
  };

  const handleAddOwner = () => {
    setOwnersCount((prevCount) => prevCount + 1);
    setOwners((prevOwners) => [
      ...prevOwners,
      {
        name: '',
        email: '',
        phone: '',
      },
    ]);
  };

  const handleRemoveOwner = (index: number) => {
    if (ownersCount > 1) {
      setOwnersCount((prevCount) => prevCount - 1);
      setOwners((prevOwners) => {
        const updatedOwners = [...prevOwners];
        updatedOwners.splice(index, 1);
        return updatedOwners;
      });
    }
  };

  const handleBlurDateOfBirth = (e: React.FocusEvent<HTMLInputElement>) => {
    if (isDateOfBirthValid(e.target.value)) {
      setBirthValid(true);
      setDateOfBirth(e.target.value);
    } else {
      setBirthValid(false);
    }
  };

  const handleSave = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const petData = {
      name: name,
      default_logo: microchipId,
      color: breed,
      breed: species,
      birthday: dateOfBirth,
      weight: Number(gender),
      notes: note ? note : 'empty',
      avatar: selectedImage,
      owners: owners,
      microchipId: microchipId,
    };

    const requiredFields = [
      'name',
      'color',
      'breed',
      'birthday',
      'weight',
      'default_logo',
    ];
    const emptyFields = requiredFields.filter(
      (field) => !petData[field as keyof typeof petData]
    );

    const filteredEmptyFields = emptyFields.filter(
      (field) =>
        !field.startsWith('ownerName_') ||
        !invalidOwnerIndices.includes(Number(field.split('_')[1]))
    );

    setEmptyFields(filteredEmptyFields);
    const ownersValid = isOwnersValid();
    console.log(filteredEmptyFields, petData);
    if (filteredEmptyFields.length === 0 && ownersValid) {
      await CreatePetProfile(petData).then((res) => {
        setShowModal(true);
        setTimeout(() => {
          navigate('/records');
        }, 2000);
      }).catch((err) => {
        toast.error(err.response.data.message);
      });
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="main-container">
      {showModal && <SuccessModal onClose={handleCloseModal} />}
      <div className="frame1">
        <div className="frame2 card">
          <div className="card_header">
            <h3 className="header_text">Create pet’s account</h3>
            <button className="header_button" onClick={handleSave}>
              Save
            </button>
          </div>
          <div className="pet_info">
            <div className="carousel">
              {images.map((image) => (
                <img
                  key={image.name}
                  src={image.source}
                  alt={image.name}
                  className={`carousel-image ${
                    selectedImage === image.name ? 'active' : ''
                  }`}
                  onClick={() => handleImageSelect(image.name)}
                />
              ))}
            </div>
            <div className="inputs-container">
              <div className="input-row">
                <div>
                  <label>Name</label>
                  <input
                    required
                    className={`input ${
                      emptyFields.includes('name') ? 'error' : ''
                    }`}
                    type="text"
                    placeholder=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {emptyFields.includes('name') && (
                    <div className="error-msg">Required field</div>
                  )}
                </div>
                <div>
                  <label>Breed</label>
                  <input
                    required
                    className={`input ${
                      emptyFields.includes('breed') ? 'error' : ''
                    }`}
                    value={species}
                    onChange={(e) => setSpecies(e.target.value)}
                  ></input>
                  {emptyFields.includes('breed') && (
                    <div className="error-msg">Required field</div>
                  )}
                </div>
              </div>
              <div className="input-row">
                <div>
                  <label>Color</label>
                  <input
                    required
                    className={`input ${
                      emptyFields.includes('color') ? 'error' : ''
                    }`}
                    type="text"
                    placeholder=""
                    value={breed}
                    onChange={(e) => setBreed(e.target.value)}
                  />
                  {emptyFields.includes('color') && (
                    <div className="error-msg">Required field</div>
                  )}
                </div>
                <div>
                  <label>Date of birth</label>
                  <input
                    required
                    className={`input ${
                      emptyFields.includes('birthday') || !birthValid
                        ? 'error'
                        : ''
                    }`}
                    type="text"
                    placeholder=""
                    onBlur={handleBlurDateOfBirth}
                  />
                  {!birthValid && (
                    <div className="error-msg">
                      Date of birth should look like mm-dd-yyyy
                    </div>
                  )}
                  {emptyFields.includes('birthday') && (
                    <div className="error-msg">Required field</div>
                  )}
                </div>
              </div>
              <div className="input-row">
                <div>
                  <label>Weight</label>
                  <input
                    required
                    className={`input ${
                      emptyFields.includes('weight') ? 'error' : ''
                    }`}
                    type="text"
                    placeholder=""
                    value={gender}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, '');

                      setGender(sanitizedValue);
                    }}
                  />
                  {emptyFields.includes('weight') && (
                    <div className="error-msg">Required field</div>
                  )}
                </div>
                <div>
                  <label>Microchip ID</label>
                  <input
                    required
                    className={`input ${
                      emptyFields.includes('default_logo') ? 'error' : ''
                    }`}
                    type="text"
                    placeholder=""
                    value={microchipId}
                    onChange={(e) => setMicrochipId(e.target.value)}
                  />
                  {emptyFields.includes('default_logo') && (
                    <div className="error-msg">Required field</div>
                  )}
                </div>
              </div>
              <label>Note</label>
              <textarea
                className="input notes"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder={
                  '○ Allergies\n' +
                  '○ Medications\n' +
                  '○ Vaccination history\n' +
                  '○ Surgical procedures\n' +
                  '○ Past and ongoing health issues'
                }
              />
            </div>
          </div>
          {owners.map((owner, index) => (
            <div key={index} className="Owners-info">
              <div className="Owners-header">
                <h3 className="Owners-text">
                  {owners.length < 2 ? 'Owner`s Info' : `Owner #${index + 1}`}
                </h3>
                {ownersCount > 1 && (
                  <button
                    className="remove-button"
                    onClick={() => handleRemoveOwner(index)}
                  >
                    &#x2715;
                  </button>
                )}
              </div>
              <div className="Owners-inputs">
                <div className="input-row">
                  <div>
                    <label>Owner’s Name</label>
                    <input
                      required
                      className={`input ${
                        emptyFields.includes(`ownerName_${index}`)
                          ? 'error'
                          : ''
                      }`}
                      type="text"
                      placeholder=""
                      value={owner.name}
                      onChange={(e) =>
                        handleOwnerChange(index, 'name', e.target.value)
                      }
                    />
                    {emptyFields.includes(`ownerName_${index}`) && (
                      <div className="error-msg">Required field</div>
                    )}
                  </div>
                  <div>
                    <label>Owner’s Email</label>
                    <input
                      required
                      className={`input ${
                        emptyFields.includes(`ownerEmail_${index}`)
                          ? 'error'
                          : ''
                      }`}
                      type="text"
                      placeholder=""
                      value={owner.email}
                      onChange={(e) =>
                        handleOwnerChange(index, 'email', e.target.value)
                      }
                    />
                    {emptyFields.includes(`ownerEmail_${index}`) && (
                      <div className="error-msg">
                        Required Email xxxxx@xxxxx.xxx
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Owner’s Phone</label>
                  <input
                    required
                    className={`input ${
                      emptyFields.includes(`ownerPhone_${index}`) ? 'error' : ''
                    }`}
                    type="text"
                    placeholder=""
                    value={owner.phone}
                    onChange={(e) =>
                      handleOwnerChange(index, 'phone', e.target.value)
                    }
                  />
                  {emptyFields.includes(`ownerPhone_${index}`) && (
                    <div className="error-msg">Required phone number</div>
                  )}
                </div>
              </div>
            </div>
          ))}

          <button className="add-button" onClick={handleAddOwner}>
            + Add another owner
          </button>
        </div>
      </div>
    </div>
  );
}
