import BackgroundImage1 from 'assets/images/background-1.png';
import BackgroundImage2 from 'assets/images/background-2.png';
import {Container} from 'components';
import CreateNewPassword from './CreateNewPassword';
import Login from './Login';
import PasswordRecovery from './PasswordRecovery';
import SignUp from './SignUp';
import './Authorization.scss';
import VerifyEmail from './VerifyEmail';
interface Props {
  form: 'login' | 'sign-up' | 'password-recovery' | 'create-password' | 'verify-email';
}
export default function Authorization(props: Props) {
  return (
    <div className="wrapper">
      <span
        className="background-image"
        style={{backgroundImage: `url(${BackgroundImage1})`}}
      />
      <span
        className="background-image-2"
        style={{backgroundImage: `url(${BackgroundImage2})`}}
      />
      
      <Container direction="row" content="center">
        {props.form === 'sign-up' && <SignUp/>}
        {props.form === 'login' && <Login />}
        {props.form === 'password-recovery' && <PasswordRecovery />}
        {props.form === 'verify-email' && <VerifyEmail />}
        {props.form === 'create-password' && <CreateNewPassword />}
      </Container>
    </div>
  );
}
