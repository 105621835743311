import React from 'react';
import './modal.css';
import successSvg from '../../assets/images/success.svg';

interface SuccessModalProps {
  onClose: () => void;
}

function SuccessModal({ onClose }: SuccessModalProps) {
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <img src={successSvg} alt="Success" className="success-svg" />
        <h3>Congratulations!</h3>
        <p>Pet’s account successfully saved!</p>
      </div>
    </div>
  );
}

export default SuccessModal;
