import styled from '@emotion/styled';
import { Box, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';


const ProfileInput = styled(TextField)`
  & .MuiInputBase-root {
    border-radius: 8px;
    border-color: #DADADA;
  }

  & input {
    color: #2F5EA0;
    font-size: 15px;
    font-family: Outfit;
  }
`;

interface InputProps {
  label: string;
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  enabled?: boolean;
}

export function PetProfileInput({ label, value, setValue, enabled = false }: InputProps) {
  const [empty, setEmpty] = useState('');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          color: '#FF974D',
          fontFamily: 'Outfit'
        }}
      >
        {label}
      </Typography>

      <ProfileInput 
        sx={{
          width: '371px',
          pointerEvents: enabled ? 'all' : 'none'
        }}
        placeholder={label}
        autoComplete='off'
        value={value}
        onChange={(e) => setValue ? setValue(e.target.value) : setEmpty('')}
      />
    </Box>
  );
}