import { InputAdornment, TextField, Button } from '@mui/material';
import { useState, FocusEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useClinicStore } from 'store';
import './Overview.scss';

import dog from '../../assets/images/dog.png';
import cat from '../../assets/images/cat.png';
import lizard from '../../assets/images/lizard.png';
import rabbit from '../../assets/images/rabbit.png';
import rooster from '../../assets/images/parrot.png';
import snake from '../../assets/images/snake.png';
import fish from '../../assets/images/fish.png';
import turtle from '../../assets/images/turtle.png';
import hamster from '../../assets/images/hamster.png';
import defaultImage from '../../assets/images/default.png';
import { useNavigate } from 'react-router-dom';

const imageComponents = [
  { name: 'dog', source: dog },
  { name: 'cat', source: cat },
  { name: 'lizard', source: lizard },
  { name: 'rabbit', source: rabbit },
  { name: 'rooster', source: rooster },
  { name: 'snake', source: snake },
  { name: 'fish', source: fish },
  { name: 'turtle', source: turtle },
  { name: 'hamster', source: hamster },
  { name: 'no avatar', source: defaultImage },
];

export default function Overview() {
  const [images, setImages] = useState(imageComponents);
  const { appointments } = useClinicStore((store) => store.clinic);
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const getAvatarSource = (avatar: string) => {
    const image = images.find((image) => image.name === avatar);
    return image ? image.source : null;
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    console.log('qwe', event.target.value);
  };

  const handleButtonClick = (id: number) => {
    navigate(`/pet-profile/${id}`);
  };

  return (
    <div className='overview'>
      <div className='overview__container'>
        <div className='overview__header'>
          <div className='overview__title'>Recent activity</div>
          <div className='overview__search-container'>
            <TextField
              className='overview__search'
              variant='outlined'
              type='text'
              size='small'
              onBlur={handleBlur}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className='overview__hl'></div>
        <div className='overview__table-container'>
          <table className='overview__table'>
            <thead className='overview__table-head'>
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>Last Visit</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {appointments?.history?.filter(item => (
                item.pet.profile?.name.toLowerCase().includes(search.toLowerCase().trim())
                || item.pet.profile?.id.toString().includes(search.toLowerCase().trim())
                || item.description.toLowerCase().includes(search.toLowerCase().trim())
                ))
                .map((item) => (
                <tr key={item.id}>
                  <td className='overview__table-first-col'>
                    <img
                      src={
                        getAvatarSource(item.pet.profile?.avatar) ||
                        'https://www.danchankoinc.com/wp-content/uploads/2017/01/nophoto.png'
                      }
                      className='overview__table-img'
                      alt={item.pet.profile?.name}
                    />
                    <div className='overview__table-text-container'>
                      <div className='overview__table-type-name'>
                        <div className='overview__table-type'>
                          {item.pet.profile?.breed}
                        </div>
                        <div className='overview__table-name'>
                          {item.pet.profile?.name}
                        </div>
                      </div>
                      <div className='overview__table-age'>
                        {new Date(
                          item.pet.profile?.birthday
                        ).toLocaleDateString()}
                      </div>
                    </div>
                  </td>
                  <td className='overview__table-id'>#{item.pet.profile?.id}</td>
                  <td className='overview__table-date'>
                    {new Date(item.start_time).toLocaleDateString()}
                  </td>
                  <td className='overview__table-button-column'>
                    <div>
                      <Button
                        variant='outlined'
                        style={{
                          width: '100px',
                          height: '31px',
                          borderRadius: '22px',
                          marginTop: '-31px',
                        }}
                        onClick={() => handleButtonClick(item.pet.profile.id)}
                      >
                        Details
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
