import React from 'react';
import './Container.scss';

interface Props {
  children: React.ReactNode
  direction?: 'row' | 'column'
  content?: string
  items?: string
  withHeader?: boolean
}

export default function Container(props: Props) {
  return (
    <div className={`container ${ props.withHeader ? 'with-header' : ''}`} 
      style={{
        flexDirection: props.direction || 'row',
        justifyContent: props.content || 'start',
        alignItems: props.items || 'start'
      }}
    >
      {props.children}
    </div>
  );
}
