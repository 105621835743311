import React, {FormEvent} from 'react';
import Logo from 'assets/images/logo.png';
import './Form.scss';
interface Props {
  submitHandler: () => Promise<void> | void;
  title: string
  children: React.ReactNode
}
export default function Form(props: Props) {
  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    props.submitHandler();
  };
  return (
    <form onSubmit={submitHandler} className="form">
      <img src={Logo} alt="" className="form__logo"/>
      <h3 className="form__title">{props.title}</h3>
      {props.children}
    </form>
  );
}
