import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  fetchGetClinicInfo,
  fetchUpdateClinicInfo,
  fetchClinicAppointments,
  fetchGetVetParams,
} from './api';

import { useAuthStore } from './authorization';

type Purpose = {
  id: number;
  name: string;
};

type Animal = {
  id: number;
  name: string;
};

type VetParams = {
  purposes: Purpose[];
  animals: Animal[];
};

type Profile = {
  about: string | null;
  avatar: string;
  created_at: string;
  email: string;
  googleRefreshToken: string;
  id: number;
  name: string;
  phone: string;
} | null;

type Clinic = {
  id: number;
  location: string;
  license: string;
  registration: string;
  address: string;
  isLoading: string;
  created_at: string;
  veterinarians: Vet[];
  profile?: Profile; // add profile to the clinic type
  appointments: Appointments;
  purposes?: string[];
  animals?: string[];
  pets?: Pet[];
};

type Slot = {
  id: number;
  start_time: string;
  end_time: string;
  duration: number;
  vetId: number;
  status: string;
  created_at: string;
};

type ProfilePet = {
  id: number;
  color: string;
  breed: string;
  name: string;
  weight: number;
  birthday: string;
  notes: string;
  avatar: string;
  created_at: string;
};
interface ProfileVet {
  id: number;
  name: string;
  googleRefreshToken: string;
  email: string;
  phone: string | null;
  about: string | null;
  avatar: string;
  created_at: string;
}

interface Vet {
  id: number;
  title: string;
  associations: string;
  speciality: string;
  location: string;
  experience_from: string;
  rate: string;
  is_fired: boolean;
  about: string;
  created_at: string;
  profile: ProfileVet;
  time_zone: number;
}
type Pet = {
  id: number;
  is_archived: boolean;
  default_logo: string;
  created_at: string;
  profile: ProfilePet;
  microchip: string;
};

type Appointment = {
  id: number;
  start_time: string;
  end_time: string;
  duration: number;
  vetId: number;
  slotId: number;
  description: string;
  status: string;
  created_at: string;
  slot: Slot;
  pet: Pet;
  purpose?: string;
};

type Appointments = {
  upcoming: Appointment[];
  history: Appointment[];
};

type ClinicState = {
  clinic: Clinic;
  vetParams: VetParams;
  isLoading: boolean;
  fetchClinicData: () => Promise<Clinic>;
  updateClinicInfo: (data: UpdateClinicData) => Promise<void>;
  updateVetInfo: (data: UpdateClinicData) => Promise<void>;
  fetchClinicAppointments: () => Promise<void>;
  fetchVetParams: () => Promise<void>;
};

type Actions = {
  fetchClinicData: () => Promise<Clinic>;
  updateClinicInfo: (data: UpdateClinicData) => Promise<void>;
  updateVetInfo: (data: UpdateClinicData) => Promise<void>;
  fetchClinicAppointments: () => Promise<void>;
  fetchVetParams: () => Promise<void>;
  resetStoreClinic: () => void;
};

type ErrorResponse = {
  status: number;
  message: string;
};

type UpdateClinicData = {
  name?: string;
  location?: string;
  phone?: string;
  license?: string;
  about?: string;
  avatar?: string;
};

const initialState: Clinic = {
  id: 0,
  location: '',
  license: '',
  registration: '',
  created_at: '',
  address: '',
  veterinarians: [],
  profile: null,
  appointments: {
    upcoming: [],
    history: [],
  },
  isLoading: ''
};
const authStore = useAuthStore; // get reference to authStore

export const useClinicStore = create(
  persist<ClinicState & Actions>(
    (set, get, api) => ({
      clinic: initialState,
      vetParams: {
        purposes: [],
        animals: [],
      },
      isLoading: true, // Initially, the loading state is true
      fetchClinicData: async () => {
        try {
          const response = await fetchGetClinicInfo();
          set({
            clinic: response.data,
            isLoading: false, // Set the loading state to false once the data has been fetched
          });
          return response.data;
        } catch (error: any) {
          const err: ErrorResponse = error.response;
          console.error(err.status);
          if (err.status === 401) {
            authStore.getState().setAuth(false);
            set({ clinic: initialState, isLoading: false });
          }
          console.error('Failed to fetch clinic data: ', error);
        }
      },
      fetchVetParams: async () => {
        try {
          const response = await fetchGetVetParams();
          set({
            vetParams: response.data,
          });
        } catch (error: any) {
          console.error('Failed to fetch vet params: ', error);
        }
      },
      updateClinicInfo: async (data: UpdateClinicData) => {
        try {
          await fetchUpdateClinicInfo(data);
          const response = await fetchGetClinicInfo();
          set({
            clinic: response.data,
          });
        } catch (error: any) {
          const err: ErrorResponse = error;
          if (err.status === 401) {
            authStore.getState().setAuth(false);

            set({ clinic: initialState });
          }
          console.error('Failed to update clinic info: ', error);
          throw err;
        }
      },
      updateVetInfo: async (data: UpdateClinicData) => {
        try {
          await fetchUpdateClinicInfo(data);
          const response = await fetchGetClinicInfo();
          set({
            clinic: response.data,
          });
        } catch (error: any) {
          const err: ErrorResponse = error;
          if (err.status === 401) {
            authStore.getState().setAuth(false);
            set({ clinic: initialState });
          }
          console.error('Failed to update clinic info: ', error);
        }
      },
      fetchClinicAppointments: async () => {
        try {
          const id = get().clinic.id;
          const response = await fetchClinicAppointments(id);
          set((state) => ({
            ...state,
            clinic: {
              ...state.clinic,
              appointments: response.data,
            },
          }));
        } catch (error: any) {
          console.error('Failed to fetch clinic appointments: ', error);
        }
      },
      resetStoreClinic: () =>
        set(() => ({
          clinic: initialState,
          vetParams: {
            purposes: [],
            animals: [],
          },
          isLoading: true, // When resetting, set the loading state back to true
        })),
    }),

    {
      name: 'clinic',
    }
  )
);
