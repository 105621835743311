import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Kalend, { CalendarView, OnEventDragFinish } from 'kalend';
import { useEffect, useState } from 'react';
import { useClinicStore } from 'store';
import { toast } from 'react-toastify';
import { fetchVetAppointments } from 'store/api';
import moment from 'moment';

export function VetView(props: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [appointments, setAppointments] = useState([]);
  const vetId = searchParams.get('vetId');

  const [timezone, setTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    try {
      const getApps = async () => {
        const apps = await fetchVetAppointments(vetId as string);
        const events = apps.filter(
          (appointment: any) =>
            appointment.status !== 'CANCELED' &&
            appointment.status !== 'COMPLETED' &&
            appointment.vetId.toString() === vetId
        ).map((appointment: any) => ({
          startAt: moment(appointment.start_time).toISOString(),
          endAt: moment(appointment.end_time).toISOString(),
          summary: appointment.purpose || appointment.description,
          color: 'blue',
          calendarID: 'work', // Replace with logic to determine calendarID
          ...appointment,
        }));
        setAppointments(events);
      };

      getApps();
    } catch {
      toast.error('Unable to get appointments');
    }
  }, [vetId]);

  const onNewEventClick = (data: any) => {
    return 'ok';
  };

  const onEventClick = (data: any) => {
    return 'ok';
  };

  const onEventDragFinish = (prev: any, current: any, data: any) => {
    return 'ok';
  };

  if (!vetId) {
    return (
      <Typography>
        Invalid link.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh'
      }}
    >
      <Kalend
        initialView={CalendarView.WEEK}
        events={appointments}
        initialDate={new Date().toISOString()}
        hourHeight={60}
        focusHour={12}
        timezone={timezone}
        disabledDragging
        showTimeLine={true}
        isDark={false}
        autoScroll={true}
      />
    </Box>
  );
}