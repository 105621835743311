import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function MobileAppRedirect() {
  const [isMobile, setIsMobile] = useState(false);
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice =
      /iphone|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
    setIsMobile(isMobileDevice);

    if (isMobileDevice && token) {
      const appUrl = `furrmobile://PCS/${token}`;
      window.location.assign(appUrl);
    }
  }, [token]);

  if (isMobile) {
    return (
      <div>
        <h1>Redirecting...</h1>
      </div>
    );
  } else {
    return (
      <div>
        <p>To access the application, open it on your mobile device.</p>
      </div>
    );
  }
}
