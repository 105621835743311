import React, { Dispatch, SetStateAction, useState, useRef, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import './ImageUpload.scss';
import {ReactComponent as UploadPhoto} from 'assets/images/uploadPhoto.svg';

interface ImageUploaderProps {
  setImageURL: Dispatch<SetStateAction<string>>;
  className?: string;
  image?: string;
  inputRef?: (instance: HTMLInputElement | null) => void;
}

export default function ImageUploader({
  image, setImageURL, className, inputRef
}: ImageUploaderProps) {
  const [selectedImage, setSelectedImage] = useState<FileList | null>(null);
  const [imagePreview, setImagePreview] = useState<string | undefined>(image);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setImagePreview(image);
  }, [image]);

  const onAddImage = async (file: File) => {
    const imageSize = file.size / (1024 ** 2);
    if (imageSize > 5) {
      alert('Image size is too large. Please select an image less than 5MB.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    // TODO return back when heroku env is set up correctly 
    // formData.append('upload_preset', `${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`);
    formData.append('upload_preset', 'ml_default');
    await axios
    // TODO return back when heroku env is set up correctly 
      // .post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`, formData)
      .post('https://api.cloudinary.com/v1_1/dpc2pvvc7/image/upload', formData)
      
      .then((response: AxiosResponse<any>) => {
        setImageURL(response.data.secure_url);
        // setImagePreview(response.data.secure_url);
      })
      .catch((err) => {
        setImageURL('');
      });
  };
  
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(e.target.files);
        setImagePreview(reader.result as string);
        onAddImage(file); // Trigger the upload after the file is read
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const assignRef = (node: HTMLInputElement | null) => {
    fileInputRef.current = node;
    if (typeof inputRef === 'function') {
      inputRef(node);
    }
  };
  


  return (
    <div className={className} >
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }}
        id="upload-button"
        ref={assignRef}
      />
      {imagePreview ? (
        <div className="image-preview" onClick={triggerFileSelect}>
          <img src={imagePreview} alt="Preview" />
        </div>
      ) : (
        <UploadPhoto onClick={triggerFileSelect} style={{ cursor: 'pointer' }} />
      )}
    </div>
  );
}
