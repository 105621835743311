
import Banner from 'assets/images/welcomePage/image.png';
import CalendComponent from 'components/Calendar/Calendar';
import Container from 'components/Container';
import Header from 'components/Header';
import { PrimaryButton } from 'components';
import './Appointments.scss';
import { useEffect, useState } from 'react';

export default function Appointments() {
  return (
    <>
      <CalendComponent />
    </>
  );
}
