import { Container } from 'components';
import Header from 'components/Header';

export default function Verify() {
  return (
    <>
      <Header/>
      <Container>
        <div className='welcome-page'>
          <div className='welcome-page__inner'>
            <h3 className='welcome-page__title'>
              Please, verify your email.
            </h3>
          </div>
        </div>
      </Container>
    </>
  );
}
