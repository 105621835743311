/* eslint-disable no-unused-vars */
import {create} from 'zustand';

type State = {
  onboarding: boolean
  email: string
  password: string
};
type Actions = {
  saveCredentials: (email: string, password: string) => void
};
export const useOnboardingStore = create<State & Actions>(
  (set) => ({
    onboarding: false,
    email:'',
    password: '',
    saveCredentials: (email, password) => set({onboarding: true, email, password})
  })
);
