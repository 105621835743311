import React, { FormEvent } from 'react';

import { useAuthStore } from 'store';
import SubmitButton from 'components/Form/SubmitButton';
import {CustomButton} from 'components/Buttons';
import './SimpleForm.scss';

interface Props {
  submitHandler: () => Promise<void> | void;
  title: string;
  children: React.ReactNode;
  bordered?: 'orange' | 'white' | 'none';
  extraButton?: React.ReactNode;
  logOut?: boolean;
}

export default function SimpleForm(props: Props) {
  const fetchLogOut = useAuthStore((store) => store.fetchLogOut);

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    props.submitHandler();
  };

  const handleLogout = async () => {
    try {
      await fetchLogOut();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {props.bordered ? (
        <div className={`simple-form-wrapper simple-form-wrapper__${props.bordered}`}>
          <form onSubmit={submitHandler}
            className={`simple-form simple-form__${props.bordered}`}>
           
           
            {props.children}

            { 
              props.logOut && <CustomButton
                style={{display: 'flex', alignSelf: 'flex-end'}}
                classes="custom-button__thin  "
                parentClasses="self-left"
                text="Log out"
                variant="outline"
                onClick={handleLogout}
              />
            }
          </form>
          
        </div>
      ) : (
        <form 
          onSubmit={submitHandler} 
          className="simple-form simple-form__with-shadow simple-form__with-margin"
        >
          <h3 className="simple-form__title">{props.title}</h3>
          {props.children}
        </form>

      )}
     
    </>
  );
}
