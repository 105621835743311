import { create } from 'zustand';
import { 
  fetchCancelAppointment,
  fetchCompleteAppointment,
} from './api';

import { useClinicStore } from './clinic';
const clinicStore = useClinicStore; // get reference to authStore 

type ModalStatus = 'edit' | 'create' | 'view' | null;

type AppointmentsState = {
  modalOpen: boolean,
  modalStatus: ModalStatus,
  modalData: any,
   
  openModal: (
    status: ModalStatus,
    data: string
  ) => void,
  closeModal: () => void,
  cancelAppointment: (id: number) => Promise<void>,
  completeAppointment: (id: number) => Promise<void>,
};

export const useAppointmentsStore = create<AppointmentsState>((set) => ({
  modalOpen: false,
  modalStatus: null,
  modalData: null,
  
  openModal: (status, data,) => set({
    modalOpen: true, 
    modalStatus: status,
    modalData: data, 
  }),
  closeModal: () => set({ 
    modalOpen: false,
    modalStatus: null,
    modalData: null,
  }),
  cancelAppointment: async (id: number) => {
    try {
      await fetchCancelAppointment(id);
      clinicStore.getState().fetchClinicAppointments();
    } catch (error) {
      console.log(error);
    } finally {
      set({ 
        modalOpen: false,
        modalStatus: null,
        modalData: null,
      });
    }
  },
  completeAppointment: async (id: number) => {
    try {
      await fetchCompleteAppointment(id);
      clinicStore.getState().fetchClinicAppointments();
    } catch (error) {
      console.log(error);
    }
  }
}));
