import Banner from 'assets/images/welcomePage/image.png';
import Container from 'components/Container';
import Header from 'components/Header';
import {Link} from 'react-router-dom';
import {PrimaryButton} from 'components';
import './WelcomePage.scss';

function WelcomePage() {
  return (
    <>
      <Header/>
      <Container>
        <div className="welcome-page">
          <div className="welcome-page__inner">
            <h1 className="welcome-page__title">&quot;Revolutionize Your Practice, 
              <span> Digitally</span>!&quot;
            </h1>
            <PrimaryButton className="welcome-page__button">
              <Link to="/sign-up">Get started</Link>
            </PrimaryButton>
          </div>
          <img className="welcome-page__banner" src={Banner} alt=""/>
        </div>
      </Container>
    </>
  );
}

export default WelcomePage;
