import {
  Box,
  Button,
  Divider,
  Modal,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { PetProfileInput } from 'components/PetProfile/ProfileInput';
import { PetRecord, formatDate } from 'pages/PetProfile/PetProfile';
import { FC, useState } from 'react';

type ModalProps = {
  isOpened: boolean;
  onClose: () => void;
  record: PetRecord;
  onSave: (
    recordId: number,
    newTreatment: string,
    newDiagnosis: string,
    newRecordNotes: string
  ) => void;
  onDelete: (recordId: number) => void;
};

export function EditRecordModal({
  isOpened,
  onClose,
  record,
  onSave,
  onDelete,
}: ModalProps) {
  const [newTreatment, setNewTreatment] = useState(record.treatment);
  const [newDiagnosis, setNewDiagnosis] = useState(record.diagnosis);
  const [newNote, setNewNote] = useState(record.note);

  const handleSave = async (recordId: number) => {
    await onSave(recordId, newTreatment, newDiagnosis, newNote);
    onClose();
  };

  const handleDelete = async (recordId: number) => {
    await onDelete(recordId);
    onClose();
  };
  return (
    <Modal open={isOpened} onClose={onClose}>
      <Box
        sx={{
          borderRadius: '12px',
          border: '1px dashed #979696',
          backgroundColor: '#FAFBFF',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          width: '60%',
          margin: '10% auto 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              color: '#08182F',
              fontFamily: 'Outfit',
            }}
          >
            {formatDate(record.created_at)}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <Button
              sx={{
                fontSize: '13px',
                fontWeight: 500,
                color: '#FF974D',
                fontFamily: 'Outfit',
                textTransform: 'none',
                borderColor: '#FF974D',
                borderRadius: '22px',
                width: '100px',
                height: '31px',
              }}
              variant='outlined'
              onClick={() => {
                handleSave(record.id);
              }}
            >
              Save
            </Button>

            <Button
              sx={{
                fontSize: '13px',
                fontWeight: 500,
                color: '#F55555',
                fontFamily: 'Outfit',
                textTransform: 'none',
                width: '100px',
                height: '31px',
              }}
              onClick={() => handleDelete(record.id)}
            >
              Delete
            </Button>
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <PetProfileInput
            label='Treatment'
            value={newTreatment}
            enabled={true}
            setValue={setNewTreatment}
          />

          <PetProfileInput
            label='Diagnosis'
            value={newDiagnosis}
            enabled={true}
            setValue={setNewDiagnosis}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#FF974D',
              fontFamily: 'Outfit',
            }}
          >
            Note
          </Typography>

          <TextareaAutosize
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            minRows={6}
            className='pet-profile__notes'
          />
        </Box>
      </Box>
    </Modal>
  );
}
