import './PrivacyPolicy.scss';
import { useState, useEffect } from 'react';
import { useUserStore } from 'store';

export default function PrivacyPolicy() {
  const { policy, getPolicy } = useUserStore((state) => state);

  useEffect(() => {
    getPolicy();
  }, []);

  const [activeSection, setActiveSection] = useState('');

  const handleClick = (title: string) => {
    setActiveSection(title);
    const element = document.getElementById(title);
    element && element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="privacy-policy">
      <div className="privacy-policy__title">Privacy Policy</div>
      <div className="content-wrapper">
        <div className="content-block">
          {policy && policy.map((policy: any, index: number) => (
            <div key={index} id={policy.title} 
              className={policy.title === activeSection ? 'active-section' : ''}>
              <h2 className="content-block__title">{policy.title}</h2>
              <div className="content-block__text">{policy.text}</div>
            </div>
          ))}
        </div>
        <div className="title-block">
          <div className="title-block__title">Table of Content</div>

          {policy && policy.map((policy: any, index: number) => (
            <h2 key={index} className="title-block__item" onClick={() => handleClick(policy.title)}>
              {policy.title}
            </h2>
          ))}
        </div>
      </div>
    </div>
  );
}
