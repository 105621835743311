import dog from '../assets/images/dog.png';
import cat from '../assets/images/cat.png';
import lizard from '../assets/images/lizard.png';
import rabbit from '../assets/images/rabbit.png';
import rooster from '../assets/images/parrot.png';
import snake from '../assets/images/snake.png';
import fish from '../assets/images/fish.png';
import turtle from '../assets/images/turtle.png';
import hamster from '../assets/images/hamster.png';
import defaultImage from '../assets/images/default.png';

export const imageComponents = [
    { name: 'dog', source: dog },
    { name: 'cat', source: cat },
    { name: 'lizard', source: lizard },
    { name: 'rabbit', source: rabbit },
    { name: 'rooster', source: rooster },
    { name: 'snake', source: snake },
    { name: 'fish', source: fish },
    { name: 'turtle', source: turtle },
    { name: 'hamster', source: hamster },
    { name: 'default', source: defaultImage}
  ];