import { CircularProgress } from '@mui/material';
import React from 'react';
import './PrimaryButton.scss';

interface Props {
  children?: React.ReactNode
  type?: 'submit';
  text?: string;
  className?: string
  loader?: boolean
  onClick?: () => void
}

export default function PrimaryButton(props: Props) {
  return (
    <button
      type={props.type || 'button'}
      className={`primary-button ${props.className || ''}`}
      style={props.loader ? {padding: 0} : undefined}
      onClick={props.onClick}
    >
      {props.children
        ? props.children
        : props.loader ? <CircularProgress size="24px" color="inherit"/> : props.text}
    </button>
  );
}
