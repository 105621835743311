import { Box, Button, Modal, Typography } from '@mui/material';
import { PetProfileInput } from 'components/PetProfile/ProfileInput';
import { PetOwner } from 'pages/PetProfile/PetProfile';
import { useState } from 'react';
import InputMask  from 'react-input-mask';
import { toast } from 'react-toastify';

type EditProps = {
  isOpened: boolean;
  onClose: () => void;
  onSave: (name: string, email: string, phone: string, oldEmail: string) => void;
  owner: PetOwner;
};

export function EditOwnerModal({ isOpened, onClose, onSave, owner }: EditProps) {
  const [email, setEmail] = useState(owner.profile.email);
  const [phone, setPhone] = useState(owner.profile.phone);
  const [name, setName] = useState(owner.profile.name);

  const handleSave = async () => {
    if (email && name && phone) {
      await onSave(name, email, phone, owner.profile.email);
      onClose();
    } else {
      toast.warn('All fields are required');
    }
  };

  return (
    <Modal open={isOpened} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: '#FFF',
          width: '55%',
          paddingX: '24px',
          paddingY: '42px',
          borderRadius: '12px',
          margin: '10% auto 0',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >

          <PetProfileInput
            label='Owner’s Name'
            value={name}
            enabled
            setValue={setName}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: '#FF974D',
                fontFamily: 'Outfit'
              }}
            >
              Owner’s Phone
            </Typography>

            <InputMask
              placeholder='+00 (000) 000 00 00'
              mask="+99 (999) 999 99 99"
              value={phone}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)
              }
              className='pet-profile__pet-birthday'
            />
          </Box>
        </Box>

        <PetProfileInput
          label='Owner’s Email'
          value={email}
          enabled
          setValue={setEmail}
        />

        <Button
          sx={{
            fontSize: '13px',
            fontWeight: 500,
            color: '#FF974D',
            fontFamily: 'Outfit',
            textTransform: 'none',
            borderColor: '#FF974D',
            borderRadius: '22px',
            width: '100px',
            height: '31px',
          }}
          variant='outlined'
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
}