import {Link, useNavigate, useParams} from 'react-router-dom';
import {Form} from 'components';
import PasswordInput from 'components/Form/PasswordInput';
import SubmitButton from 'components/Form/SubmitButton';
import {useAuthStore} from 'store';
import {useState} from 'react';

export default function CreateNewPassword() {
  const navigate = useNavigate();
  const {token} = useParams();

  const fetchCreateNewPassword = useAuthStore((store) => store.fetchCreateNewPassword);

  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!password || !passwordConfirm) return setError(true);
    setLoading(true);
    fetchCreateNewPassword(password, token || '')
      .then(() => {
        // todo toast "Successfully changed"
        setTimeout(() => navigate('/login'), 1000);
      })
      .catch(() => [])
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      submitHandler={handleSubmit}
      title="Create new password"
    >
      <PasswordInput
        confirm
        setPassword={setPassword}
        auth={true}
        setPasswordConfirm={setPasswordConfirm}
        isEmpty={error}
        password={password}
      />
      <SubmitButton text="Reset password" loader={loading}/>
      <p className="form__tooltip">
        Don’t have an account?
        <Link to="/sign-up">Sign Up</Link>
      </p>
    </Form>
  );
}
