/* eslint-disable no-unused-vars */
import { ReactComponent as Hide } from 'assets/images/hide.svg';
import { ReactComponent as Info } from 'assets/images/info.svg';
import { ReactComponent as Show } from 'assets/images/show.svg';
import { ReactComponent as Validation } from 'assets/images/validation.svg';
import { useEffect, useState, useRef } from 'react';
import './Input.scss';

interface Props {
  className?: string;
  type: 'email' | 'password' | 'text';
  value: string;
  setValue?: (value: string) => void;
  placeholder: string;
  onBlur: (str: string) => void;
  label?: string;
  error: string | null;
}

export default function Input(props: Props) {
  const [value, setValue] = useState<string>(props.value);
  const [type, setType] = useState<string>(props.type);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  //////////////////////////////////////
  const inputRef = useRef<HTMLInputElement | null>(null);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (inputRef.current && inputRef.current.value !== value) {
  //       const newValue = inputRef.current.value;
  //       setValue(newValue);
  //       props.onBlur(newValue);
  //     }
  //   }, 1000); // checks every second

  //   // cleanup function
  //   return () => clearInterval(intervalId);
  // }, []); // Run only once on component mount
  ///////////////////////
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    if(props.setValue) {
      props.setValue(newValue);
    }
  };

  return (
    <fieldset className="fieldset">
      {props.label && <label className="label">
        {props.label}
        {props.label == 'Address' && 
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <Info />
          {showTooltip && 
            <div className="tooltip-content">
                Address example<br />
                JSP INC<br />
                105 KROME AVE<br />
                MIAMI FL 33135-3700 USA
            </div>
          }
        </div>}
      </label>}
      <div className="inner">
        <input
          className={`input ${props.className} ${props.error ? 'error' : ''}`}
          type={type}
          ref={inputRef}
          value={value}
          placeholder={props.placeholder}
          onChange={handleChange}
          onBlur={() => props.onBlur(value)}
        />
        {props.type === 'password' && (
          <button
            type="button"
            onClick={() => setType(type === 'text' ? props.type : 'text')}
          >
            {type === 'text' ? <Show /> : <Hide />}
          </button>
        )}
      </div>
      {props.error && (
        <span className="tooltip">
          <Validation />
          {props.error}
        </span>
      )}
    </fieldset>
  );
}
