import { Link, useNavigate, useLocation } from 'react-router-dom';
import EmailInput from 'components/Form/EmailInput';
import { Form } from 'components';
import PasswordInput from 'components/Form/PasswordInput';
import SubmitButton from 'components/Form/SubmitButton';
import { useAuthStore, useClinicStore } from 'store';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { errorMonitor } from 'stream';

export default function Login() {
  const navigate = useNavigate();

  const fetchLogin = useAuthStore((store) => store.fetchLogin);
  const fetchClinicData = useClinicStore((state) => state.fetchClinicData);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    console.log('login');
    if (!email || !password) return setError(true);
    fetchLogin(email, password)
      .then(async () => {
        const data = await fetchClinicData();
        console.log(data);
        if(!data?.address) {
          navigate('/clinic-registration');
        }
        else {
          navigate('/');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || 'Incorrect credentials', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          toastId: 'uniqueId',
        });
      })
      .finally(() => setLoading(false));
  };

  const query = new URLSearchParams(useLocation().search);
  const verify = query.get('verify');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice =
      /iphone|android|blackberry|mini|windows\sce|palm/i.test(userAgent);

    if (isMobileDevice && verify === 'true') {
      const appUrl = 'furrmobile://WS';
      window.location.assign(appUrl);
    }
  }, []);

  return (
    <Form submitHandler={handleSubmit} title='Login with Email'>
      <EmailInput setEmail={setEmail} auth={true} isEmpty={!email && error} />
      <PasswordInput
        password={password}
        auth={true}
        setPassword={setPassword}
        noStrong
        isEmpty={!email && error}
      />
      <Link className='form__forgot-password' to='/password-recovery'>
        Forgot password?
      </Link>
      <SubmitButton text='Continue' loader={loading} />
      <p className='form__tooltip'>
        Don’t have an account?
        <Link to='/sign-up'>Sign Up</Link>
      </p>
    </Form>
  );
}
