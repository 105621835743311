import { create } from 'zustand';
import { fetchGetFAQ, fetchGetUserPolicy } from './api';

type ModalStatus = 'success' | 'error' | 'warning' | null;
type ModalExtraButtons = 'deleteVet' | 'deleteAppointment' | null;
interface FAQItem {
  question: string;
  answer: string;
}

interface PolicyItem {
  title: string;
  text: string;
}

type UserState = {
  popupVisible: boolean,
  togglePopup: () => void,
  modalOpen: boolean,
  modalStatus: ModalStatus,
  modalHeader: string,
  policy: PolicyItem[] | null,
  modalBody: string,
  modalExtraButtons: ModalExtraButtons,
  modalExtraData?: any,
  openModal: (
    status: ModalStatus,
    header: string,
    body: string,
    modalExtraButtons: ModalExtraButtons,
    modalExtraData?: any
  ) => void,
  closeModal: () => void,
  faq: FAQItem[] | null, // faq is an array of FAQItem or null
  getFAQ: () => Promise<void>,
  getPolicy: () => Promise<void>,
};

export const useUserStore = create<UserState>((set, get) => ({
  popupVisible: false,
  togglePopup: () => set((state) => ({ popupVisible: !state.popupVisible })),
  modalOpen: false,
  modalStatus: null,
  modalHeader: '',
  modalBody: '',
  modalExtraButtons: null,
  modalExtraData: null,
  openModal: (status, header, body, modalExtraButtons, extraData) => set({
    modalOpen: true, 
    modalStatus: status,
    modalHeader: header, 
    modalBody: body,
    modalExtraButtons, 
    modalExtraData: extraData
  }),
  closeModal: () => set({ 
    modalOpen: false,
    modalStatus: null,
    modalHeader: '',
    modalBody: '',
    modalExtraButtons: null,
    modalExtraData: null
  }),
  faq: null,
  policy: null,
  getPolicy: async () => {
    try {
      const response = await fetchGetUserPolicy();
      set({ policy: response.data }); // if response.data is an object with an `items` array
    } catch (error) {
      console.error('Failed to fetch policy:', error);
    }
  },
  getFAQ: async () => {
    try {
      const response = await fetchGetFAQ();
      set({ faq: response.data }); // if response.data is an object with an `items` array
    } catch (error) {
      console.error('Failed to fetch FAQ:', error);
    }
  },
}));
