import './FAQ.scss';
import {useState, useEffect} from 'react';
import { useUserStore } from 'store';
import SimpleForm from 'components/Form/SimpleForm';
import SubmitButton from 'components/Form/SubmitButton';
import TextInput from 'components/Form/TextInput';
import EmailInput from 'components/Form/EmailInput';


export default function FAQ() {
  const { faq, getFAQ } = useUserStore((state) => state);
  useEffect(() => {
    getFAQ();
  }, []);


  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const handleSubmit = async () => {
    console.log('submit');
    console.log('name', name);
    console.log('email', email);
    console.log('message', message);
  };


  return (
    <div className="faq">
      <div className="faq__content">
        <SimpleForm
          submitHandler={handleSubmit}
          title="Contact us"
        >
          <EmailInput label="Email" setEmail={setEmail} isEmpty={false}/>
          <TextInput name="Name" setText={setName} isEmpty={false}/>
          <TextInput name="Message" setText={setMessage} isEmpty={false}/>
          <SubmitButton
            text="Submit"
            loader={false} 
            className={'primary-button__orange primary-button__small'
            }/>
        </SimpleForm>
        
      </div>
      

    </div>
  );
}


