import {error, regex} from '../../data';
import {useEffect, useState} from 'react';
import Input from '../Input';

interface Props {
  // eslint-disable-next-line no-unused-vars
  setEmail: (email: string) => void;
  label?: string;
  isEmpty: boolean;
  primaryColor?: boolean;
  value?: string;
  auth?: boolean;
}

export default function EmailInput(props: Props) {

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string | null>(null);

  useEffect(() => {
    if (props.isEmpty) {
      if (!email) setEmailError(error.emptyEmail);
    }
  }, [props.isEmpty, email]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    props.setEmail(newEmail);
    setEmailError(null);
  };

  const handlerEmailBlur = async (email: string) => {
    const emailIsCorrect = email.match(regex.email);
    if (!emailIsCorrect) {
      !email
        ? setEmailError(error.emptyEmail)
        : setEmailError(error.emailFormat);
    } else {
      setEmail(email);
      props.setEmail(email);
      setEmailError(null);
    }
  };

  return (
    <Input
      className={`form__input 
       ${(props.primaryColor ? ' primaryColor' : '')}
       ${(props.auth ? ' auth-input' : '')}
       `}
      type="email"
      placeholder="Email"
      label={props.label}
      error={emailError}
      value={email || props.value || ''}
      setValue={props.setEmail}
      onBlur={handlerEmailBlur}/>
  );
}
