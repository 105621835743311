import axios from 'axios';
import { PetRecord, ProfilePet } from 'pages/PetProfile/PetProfile';

// const baseURL = process.env.NODE_ENV === 'development' ?
//   'http://localhost:8000/api/' : (process.env.REACT_APP_API || '');

const baseURL = process.env.REACT_APP_API;
// const baseURL ='http://localhost:8000';
const request = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache',
  },
});

type PetData = {
  name: string;
  default_logo: string;
  color: string;
  breed: string;
  birthday: string;
  microchipId: string;
  weight: number;
  notes: string;
  avatar: string;
  owners: any[]; // Assuming owners has an array of any type
};

export const fetchSignUp = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => await request.post('/api/clinic/sign-up', { email, password });

export const fetchSignIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => await request.post('/api/clinic/sign-in', { email, password });

export const fetchCheckEmail = async (email: string) =>
  await request.get(`/api/auth/check?email=${email}`);

export const fetchCreateNewPassword = async (
  password: string,
  token: string
) => {
  await request.post(`/api/auth/change-password/${token}`, { password });
};

export const fetchLogOutClinic = async () =>
  await request.get('/api/auth/logout');

export const fetchUpdateClinicInfo = async (data: any) =>
  await request.post('/api/clinic/update', data);

export const fetchPasswordRecovery = async (email: string) =>
  await request.get(`/api/auth/password-recovery?email=${email}`);

export const fetchLogOut = async () => await request.get('/api/auth/logout');

export const fetchGetClinicInfo = async () => await request.get('/api/clinic');

export const fetchGetFAQ = async () => await request.get('/api/user/FAQ');

export const fetchGetUserPolicy = async () =>
  await request.get('/api/user/policy');

export const fetchVetRegister = async (data: any) =>
  await request.post('/api/vet/register', data);

export const fetchVetUpdate = async (data: any) =>
  await request.patch('/api/vet/update-vet', data);

export const fetchVetDelete = async (data: any) =>
  await request.delete('/api/vet/delete-vet', { data });

export const fetchClinicAppointments = async (id: string | number) =>
  await request.get('/api/appointment/clinic-appoitments', { params: { id } });

export const fetchCancelAppointment = async (id: string | number) =>
  await request.get('/api/appointment/cancel', { params: { id } });

export const fetchGetPetsByOwner = async (data: any ) =>
  await request.post('/api/appointment/get-owners-pets', data);

export const fetchCreateAppointment = async (data: any ) =>
  await request.post('/api/appointment/create', data);

export const fetchUpdateAppointment = async (data: any ) =>
  await request.post('/api/appointment/update', data);

export const fetchCompleteAppointment = async (id: string | number) =>
  await request.get('/api/appointment/complete', { params: { id } });

export const fetchFreeSlotsByDate = async (date: string, vet_id: string) =>
  await request.get('/api/appointment/get-free-slots-by-date', { params: { date, vet_id } });
  
export const fetchGetVetParams = async () =>
  await request.get('/api/vet/find-params');

export const CreatePetProfile = async (queryParams: PetData) =>
  await request.post('/api/pet/create',queryParams);

export const fetchPetProfile = async (petId: number): Promise<ProfilePet> => {
  const { data } = await request.get(`/api/pet/profile/${petId}`);

  return data;
};

export const fetchPetRecords = async (petId: number): Promise<PetRecord[]> => {
  const { data } = await request.get(`/api/pet/records/${petId}`);

  return data;
};

export const updatePetProfile = async (petData: any) =>
  await request.post('/api/pet/update', petData);

export const createPetRecord = async (data: any) =>
  await request.post('/api/pet/create-record', data);

export const fetchVetAppointments = async (vetId: string) => {
  const { data } = await request.get(`/api/appointment/vet-appointments/${vetId}`);

  return data;
};

export const deletePetRecord = async (recordId: number) =>
  await request.delete(`/api/pet/delete-record?recordId=${recordId}`);

export const updatePetRecord = async (recordId: number, data: any) =>
  await request.post('api/pet/update-record', {
    recordId,
    ...data
  });

export const updatePetOwner = async (newData: any) => {
  const { data } = await request.post('api/pet/update-owner', newData);

  return data;
};