import {
  Appointments,
  Authorization,
  Dashboard,
  Onboarding,
  Settings,
  WelcomePage,
} from 'pages';
import { Route, Routes } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useAuthStore, useClinicStore, useUserStore } from './store';
import { useOnboardingStore } from './store/onboarding';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import MobileAppRedirect from 'pages/MobileAppRedirect';
import VetView from 'pages/VetView';
import VerifyEmail from 'pages/VerifyEmail';
import Verify from 'pages/Verify';

function App() {
  const auth = useAuthStore((store) => store.auth);
  const onboarding = useOnboardingStore((store) => store.onboarding);
  const email = useAuthStore((store) => store.user.email);

  return (
    <AnimatePresence initial={false} mode="wait">
      <ToastContainer key={1} />
      <Routes>
        <Route path="appointments/vet-view" element={<VetView />} />
        {auth ? (
          <>
            <Route path="/*" element={<Dashboard />} />
          </>
        ) : (
          <>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/mobile-redirect/:token" element={<MobileAppRedirect />} /> 
            <Route path="/verify/" element={<Verify />} /> 
            <Route path="/verified/" element={<VerifyEmail />} /> 
            <Route path="/login" element={<Authorization form="login" />} />
            <Route path="/sign-up" element={<Authorization form="sign-up" />} />
            <Route
              path="/password-recovery"
              element={<Authorization form="password-recovery" />}
            />
            <Route
              path="/verify-email"
              element={<Authorization form="verify-email" />}
            />
            <Route
              path="/change-password/:token"
              element={<Authorization form="create-password" />}
            />
            <Route path="appointments/vet-view" element={<VetView />} />
            <Route key="guest" path="/*" element={<WelcomePage />} />
          </>
        )}
      </Routes>
    </AnimatePresence>
  );
}

export default App;
