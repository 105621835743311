import {
  fetchCheckEmail, 
  fetchCreateNewPassword,
  fetchPasswordRecovery,
  fetchSignIn,
  fetchUpdateClinicInfo,
  fetchLogOut,
  fetchGetClinicInfo,
  fetchSignUp,
} from './api';

import {create} from 'zustand';
import {persist} from 'zustand/middleware';
 

type State = {
  auth: boolean,
  user: {
    email: string,
    name: string,
    avatar: string,
  },
};
type ErrorResponse = {
  status: number;
  message: string;
};

const initialState: State = {
  auth: false,
  user: {
    email: '',
    name: '',
    avatar: '',
  },
};

type Actions = {
  fetchCheckEmail: (email: string) => Promise<void>,
  fetchLogin: (email: string, password: string) => Promise<void>,
  fetchPasswordRecovery: (email: string) => Promise<void>,
  fetchCreateNewPassword: (password: string, token: string) => Promise<void>,
  fetchSignUp: (email: string, password: string) => Promise<void>,
  fetchLogOut: () => Promise<void>,
  setAuth: (value: boolean) => void, // Added a setAuth action
  resetStore: () => void,
};

export const useAuthStore = create(
  persist<State & Actions>(
    (set, get) => ({
      auth: false,
      user: {
        email: '',
        name: '',
        avatar: '',
      },
      fetchCheckEmail: async (email: string) => {
        try {
          await fetchCheckEmail(email);
        } catch (error: any) {
          const err: ErrorResponse = error;
          if (err.status === 401) {
            set({ ...initialState });
          }
          throw error;
        }
      },
      fetchSignUp: async (email: string, password: string) => {
        try {
          await fetchSignUp({email, password});
        } catch (error: any) {
          const err: ErrorResponse = error;
          if (err.status === 401) {
            set({ ...initialState });
          }
          throw error;
        }
      },
      fetchLogin: async (email: string, password: string) => {
        try {
          await fetchSignIn({email, password});
          const res = await fetchGetClinicInfo();
          set({
            auth: true,
            user: {
              email: res.data.email,
              name: res.data.name,
              avatar: res.data.avatar,
            },
          });
        } catch (error: any) {
          const err: ErrorResponse = error;
          if (err.status === 401) {
            set({ ...initialState });
          }
          throw error;
        }
      },
      fetchPasswordRecovery: async (email: string) => {
        try {
          await fetchPasswordRecovery(email);
        } catch (error: any) {
          const err: ErrorResponse = error;
          if (err.status === 401) {
            set({ ...initialState });
          }
          throw error;
        }
      },
      fetchCreateNewPassword: async (password: string, token: string) => {
        try {
          await fetchCreateNewPassword(password, token);
        } catch (error: any) {
          const err: ErrorResponse = error;
          if (err.status === 401) {
            set({ ...initialState });
          }
          throw error;
        }
      },
      fetchLogOut: async () => {
        try {
          await fetchLogOut();
          // clear all cookies
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
          }
          set({ ...initialState });
          
        } catch (error: any) {
          const err: ErrorResponse = error;
          if (err.status === 401) {
            set({ ...initialState });
             
          }
         
          throw error;
        }
      },
      
      setAuth: (value: boolean) => { // Implementation for setAuth action
        set({ auth: value });
      },
      resetStore: () => set(() => ({...initialState})),
    }),
    {
      name: 'auth',
    }
  )
);
