import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useUserStore, useClinicStore } from '../../store';
import { CustomButton } from 'components/Buttons';
import './Modal.scss';
import {ReactComponent as Success} from 'assets/images/success.svg';
import {ReactComponent as Warning} from 'assets/images/warning.svg';
import { fetchVetDelete } from 'store/api';
import { useAppointmentsStore } from 'store';

export default function CustomModal (){
  const getClinicData = useClinicStore((state) => state.fetchClinicData);
  const fetchClinicAppointments = useClinicStore((state) => state.fetchClinicAppointments);
  const navigate = useNavigate();
  const modalOpen = useUserStore((state) => state.modalOpen);
  const modalStatus = useUserStore((state) => state.modalStatus);
  const modalHeader = useUserStore((state) => state.modalHeader);
  const modalBody = useUserStore((state) => state.modalBody);
  const modalExtraButtons = useUserStore((state) => state.modalExtraButtons);
  const modalExtraData = useUserStore((state) => state.modalExtraData);
  const closeModal = useUserStore((state) => state.closeModal);

  const handleDelete = async (vetId : number) => {
    try {
      const vetData = {
        vet_id: vetId,
      };
      await fetchVetDelete(vetData);
      await getClinicData();
      await fetchClinicAppointments();
      closeModal(); 
      navigate('/overview');
    } catch (error) {
      console.error('Failed to update clinic info: ', error);
    }
  };

  const handleDeleteAction = (modalExtraButtons: string | null, modalExtraData: any) => {
    if (modalExtraButtons === 'deleteVet') {
      handleDelete(modalExtraData.vet_id);
    } else if (modalExtraButtons === 'deleteAppointment') {
      cancelAppointment(modalExtraData.id);
      closeModal(); 
    }
  };
  const cancelAppointment = useAppointmentsStore(state => state.cancelAppointment);

  // function ExtraButtons () {
  //   if (modalExtraButtons === 'deleteVet') {
  //     console.log(modalExtraData);
  //     return (
  //       <>
  //        <CustomButton
  //             text="Edit"
  //             classes={`
  //             custom-button__orange 
  //             custom-button__thin 
  //             custom-button__no-margin 
  //             custom-button__no-hover`}
  //             variant="outline"
  //             type="submit"
  //           />

  //           <CustomButton
  //             text="Remove"
  //             classes={`
  //             custom-button__red
  //             custom-button__thin 
  //             custom-button__no-margin 
  //             `}
  //             variant="ghost"
  //             onClick={async (e) => {
  //               e.preventDefault();
  //               handleDelete(modalExtraData);
  //             }}
  //           />
  //       </>
  //     );
  //   }
  // }

  function StatusImage() {
    if (modalStatus === 'success') return <Success/>;
    if (modalStatus === 'warning') return <Warning />; 

    return null;
  }

  return (
    <Modal open={modalOpen} onClose={closeModal}>
      <Box className="modal-container">
        <StatusImage />
        <div 
          className={`
          modal-container__modal-header modal-container__modal-header__${modalStatus}
          `} >
          {modalHeader}
        </div>
        <div className="modal-container__modal-body">
          {modalBody}
        </div>

        {modalExtraButtons && (
          <div className="modal-container__button-container">
            <CustomButton
              text="No, keep it"
              classes={`
              custom-button__thin
              custom-button__no-margin 
              custom-button__no-hover
              custom-button__delete-cancel
              `}
              variant="ghost"
              type="submit"
              onClick={closeModal}
            />

            <CustomButton
              text="Yes, remove"
              classes={`
              custom-button__thin 
              custom-button__no-margin 
              custom-button__delete
              `}
              variant="outline"
              onClick={(e) => {
                handleDeleteAction(modalExtraButtons, modalExtraData);
              }}
            />
          </div>
        )
        }
        
      </Box>
    </Modal>
  );
}
