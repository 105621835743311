import './ClinicRegistration.scss';
import {useState} from 'react';
import SimpleForm from 'components/Form/SimpleForm';
import SubmitButton from 'components/Form/SubmitButton';
import TextInput from 'components/Form/TextInput';
import EmailInput from 'components/Form/EmailInput';
import {useClinicStore, useUserStore} from '../../store'; // Import useClinicStore
import {useNavigate} from 'react-router-dom';
import ImageUploader from 'components/ImageUpload';
import { toast } from 'react-toastify';




export default function ClinicRegistration() {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [license, setLicense] = useState<string>('');
  const [imageURL, setImageURL] = useState<string>(''); // State for the image URL

  const updateClinicInfo = useClinicStore((state) => state.updateClinicInfo);  
  const openModal = useUserStore((state) => state.openModal);
  const phoneRegex = /^\d{5,15}$/; 

  const handleSubmit = async () => {
    if (!name ||
        !email ||
        !phone ||
        !address ||
        !license ||
        !phoneRegex.test(phone) 
    ) {
      toast.error('All fields are required', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        toastId: 'uniqueId3'
      });
      return;
    }
    const clinicData = {
      name,
      location: address,
      phone,
      address,
      license,
      email,
      avatar: imageURL  
    };

    try {
      await updateClinicInfo(clinicData);
      openModal('success', 'Congratulations!', 'Your vet clinic successfully saved!', null);
      navigate('/overview');
      
    } catch (error) {
      toast.error('Some error occured, please check your data', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        toastId: 'uniqueId5'
      });
      console.error('Failed to update clinic info: ', error);
    }
  };

  return (
    <div className="clinic-registration">
      <SimpleForm
        submitHandler={handleSubmit}
        title="Clinic registration"
        bordered={'orange'}
      >
        <div className="simple-form__header">
          <div className="simple-form__title">{'Vet clinic’s registration form'}</div>
          <SubmitButton
            text="Save"
            loader={false} 
            className={'primary-button__orange primary-button__small primary-button__no-margin'}
          />
        </div>
        <div className="clinic-registration__grid">
          {/* <ImageUploader setImageURL={setImageURL}/>  */}
          <div className="clinic-registration__item">
            <TextInput 
              name="Name of clinic" 
              placeholder={'Name'} 
              setText={setName}
              isEmpty={false}/>
          </div>
          <div className="clinic-registration__item">
            <TextInput 
              name="Address"
              placeholder={'Ex: 433 New Court Staten Island, NY 10312'} 
              setText={setAddress} 
              isEmpty={false}/>
          </div>
          <div className="clinic-registration__item">
            <EmailInput label="Email" setEmail={setEmail} isEmpty={false}/>
          </div>
          <div className="clinic-registration__item">
            <TextInput
              name="Phone number" 
              setText={setPhone} 
              placeholder={'Phone number'} 
              //  isPhone={true}
              isEmpty={false}/>
          </div>
          <div className="clinic-registration__item">
            <TextInput 
              name="Vet clinic licence"
              placeholder={'Licence'}
              setText={setLicense} 
              isEmpty={false}/>
          </div>
        </div>
      </SimpleForm>
      {/* {imageURL && <img src={imageURL} alt="Uploaded" />} Display the uploaded image */}
    </div>
  );
}

