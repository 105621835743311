import './Settings.scss';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import SimpleForm from 'components/Form/SimpleForm';
import SubmitButton from 'components/Form/SubmitButton';
import TextInput from 'components/Form/TextInput';
import EmailInput from 'components/Form/EmailInput';
import {useClinicStore, useUserStore} from '../../store'; // Import useClinicStore
import ImageUploader from 'components/ImageUpload';
import {CustomButton} from 'components'; // Import the CustomButton component
import { toast } from 'react-toastify';
import { Link } from '@mui/material';

export default function Settings() {
  const navigate = useNavigate();

  const { clinic } = useClinicStore(state => state);
  const fetchClinicData = useClinicStore((state) => state.fetchClinicData);
  const updateClinicInfo = useClinicStore((state) => state.updateClinicInfo);  
  const fetchClinicAppointments = useClinicStore((state) => state.fetchClinicAppointments);

  const openModal = useUserStore((state) => state.openModal);

  useEffect(() => {
    fetchClinicData();
  }, [fetchClinicData]);

  const [name, setName] = useState<string>(clinic.profile?.name || '');
  const [email, setEmail] = useState<string>(clinic.profile?.email || '');
  const [phone, setPhone] = useState<string>(clinic.profile?.phone || '');
  const [address, setAddress] = useState<string>(clinic.address || '');
  const [license, setLicense] = useState<string>(clinic.license || '');
  const [imageURL, setImageURL] = useState<string>(clinic.profile?.avatar || '');

  const phoneRegex = /^\d{5,15}$/; 
  // Populate local state with fetched clinic data
  useEffect(() => {
    if (clinic) {
      setName(clinic.profile?.name || '');
      setEmail(clinic.profile?.email || '');
      setPhone(clinic.profile?.phone || '');
      setAddress(clinic.address || '');
      setLicense(clinic.license || '');
      if(clinic.profile) {
        setImageURL(clinic.profile.avatar || '');
      }
    }
  }, [clinic, fetchClinicData]);
  
 
 
  const handleSubmit = async () => {
    if (!name.trim() ||
        !email.trim() || 
        !phone.trim() ||
        !phoneRegex.test(phone) ||
        !address.trim() ||
        !license.trim()){
      toast.error('Please, check your data', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        toastId: 'uniqueId1'
      });
      return;
    }

    const clinicData = {
      name,
      location: address,
      address,
      phone,
      license,
      email,
      avatar: imageURL 
    };

    try {
      await updateClinicInfo(clinicData);
      await fetchClinicAppointments();
      openModal('success', 'Congratulations!', 'Your vet clinic successfully updated!', null);
      navigate('/overview');
    } catch (error: any) {
      if (error.response.status === 401) {
        return;
      }
      toast.error('Please, check your data', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        toastId: 'uniqueId1'
      });
    }
  };

  return (
    <div className="settings">
      <SimpleForm
        submitHandler={handleSubmit}
        title={`Clinic ${clinic.profile?.name}`}
        bordered={'none'}
        logOut={true}
      >
        <div className="simple-form__header">
          <div className="simple-form__title">{`Clinic ${clinic.profile?.name}`}</div>
          <CustomButton
            text="Edit"
            classes={`
            custom-button__orange 
            custom-button__thin
            custom-button__mt-10
            custom-button__no-hover`}
            variant="outline"
            // style={{marginTop: '10px !important'}}
          />
        </div>
        <hr className="hr-settings-top"/>
        <div className="settings__grid">
          {/* <ImageUploader setImageURL={setImageURL}/>  */}
          <div className="settings__item">
            <TextInput name="Name of clinic" value={name} setText={setName} isEmpty={false}/>
          </div>
          <div className="settings__item">
            <TextInput 
              name="Address" 
              value={address}
              placeholder={'Ex: 433 New Court Staten Island, NY 10312'} 
              setText={setAddress}
              isEmpty={false}/>
          </div>
          <div className="settings__item">
            <EmailInput 
              label="Email"
              value={email}
              setEmail={setEmail} 
              isEmpty={false}/>
          </div>
          <div className="settings__item">
            <TextInput 
              name="Phone number" 
              value={phone}
              setText={setPhone}
              isEmpty={false}/>
          </div>
          <div className="settings__item">
            <TextInput name="Vet clinic licence"
              value={license}
              setText={setLicense} 
              isEmpty={false}/>
          </div>
          
        </div>
        <hr className="hr-settings-bottom"/>
        
      </SimpleForm>
      {/* {imageURL && <img src={imageURL} alt="Uploaded" />} Display the uploaded image */}
      <div className="settings__bottom">
        <Link className="settings__bottom__link" href="https://assets-global.website-files.com/65153ab5e03b09dad0394716/654a2c233fbce2c96564edfa_PrivacyPolicyFURR.pdf" target="_blank">
          Privacy Policy
        </Link>
        <Link className="settings__bottom__link" href="https://assets-global.website-files.com/65153ab5e03b09dad0394716/654a28143543ee4f0c9f9931_TermsAndConditionsFURR.pdf" target="_blank">
          Terms&Conditions
        </Link>
      </div>
    </div>
  );
}
