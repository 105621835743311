import {Link} from 'react-router-dom';
import { useUserStore, useAuthStore, useClinicStore } from '../../store';
import Logo from 'assets/images/logo.png';
import './Header.scss';

function Header() {
  const auth = useAuthStore((store) => store.auth);
  const { popupVisible, togglePopup } = useUserStore();
  const clinic = useClinicStore((store) => store.clinic);
  const { location, license, registration, created_at, profile } = clinic;
  const register = location === '' ||
                  license === '' ||
                  profile === null ||
                  !profile?.name ||
                  !profile?.phone;



  return (
    <div className="header">
      <a href="/overview" className="header__logo">
        <img src={Logo} alt=""/>
      </a>

      
      { auth ? (
        <>
          {!register &&
            <div className="header_nav">
              <Link className="header__auth-link" to="/overview">Overview</Link>
              <Link className="header__auth-link" to="/appointments">Appointments</Link>
              <Link className="header__auth-link" to="/records">Medical Records</Link>
              <Link className="header__auth-link" to="/faq">Support</Link>
            </div>
          }
          <div className="header__btn-group">
            <div onClick={togglePopup} className="header__auth-link">Account</div>
          
          </div>
        
        </>
      ) : (
        <div className="header__btn-group">
          <Link className="header__auth-link" to="/sign-up">Sign Up</Link>
          <Link className="header__auth-link" to="/login">Login</Link>
        </div>
      )
      }
       
      
    </div>
  );
}

export default Header;
