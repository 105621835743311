import {Link, useNavigate} from 'react-router-dom';
import EmailInput from 'components/Form/EmailInput';
import {Form} from 'components';
import PasswordInput from 'components/Form/PasswordInput';
import SubmitButton from 'components/Form/SubmitButton';
import {useAuthStore} from 'store';
import {useOnboardingStore} from 'store/onboarding';
import {useState} from 'react';
import {toast} from 'react-toastify';

export default function SignUp() {
  const navigate = useNavigate();

  const fetchSignUp = useAuthStore((store) => store.fetchSignUp);
  const setAuth = useAuthStore((store) => store.setAuth);
  const saveCredentials = useOnboardingStore((store) => store.saveCredentials);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorConfirm, setErrorConfirm] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!password) return setError(true);
    if (!passwordConfirm) return setErrorConfirm(true);
    if (!email) return setErrorEmail(true);
    // if (password !== passwordConfirm) return setError(true);
    setLoading(true);
    fetchSignUp(email, password)
      .then(async () => {
        setAuth(true);
        await saveCredentials(email, password);
        navigate('/verify-email');
        setError(false);
        setErrorEmail(false);
      })
      .catch(() => {
        toast.error('The email address is already registered.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'light',
          toastId: 'uniqueId1'
        });
        
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      submitHandler={handleSubmit}
      title="Sign Up with Email"
    >
      <EmailInput 
        auth={true}
        setEmail={setEmail} 
        isEmpty={errorEmail}/>
      <PasswordInput
        confirm
        auth={true}
        setPassword={setPassword}
        setPasswordConfirm={setPasswordConfirm}
        isEmptyConfirm={errorConfirm}
        isEmpty={error}
        password={password}
        confirmPassword={passwordConfirm}
      />
      <SubmitButton text="Continue" loader={loading}/>
      <p className="form__tooltip">
        Already have an account?
        <Link to="/login">Login</Link>
      </p>
    </Form>
  );
}
