export const error = {
  emptyEmail: 'Email can\'t be empty',
  emailFormat: 'Please use format xxxxx@xxxx.xxx',
  emptyPassword: 'Password can\'t be empty',
  weakPassword: 'Password is too weak',
  emptyConfirmPassword: 'Password confirmation can\'t be empty',
  differentPasswords: 'Passwords are different',
  required: 'Required',
  email: 'email',
  unknown: 'Email is not',
  experience: 'Experience should be a number',
  phone: 'Phone should be valid',
};

export const success = {
  codeSent: 'We\'ve sent a verification code to your email',
};
