import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Banner from 'assets/images/welcomePage/image.png';
import ProfilePopUp from 'components/ProfilePopUp';
import Container from 'components/Container';
import Header from 'components/Header';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Overview from 'pages/Overview';
import Appointments from 'pages/Appointments';
import EditDoctor from 'pages/EditDoctor';
import Settings from 'pages/Settings';
import FAQ from 'pages/FAQ';
import { useUserStore, useClinicStore } from '../../store';
import './Dashboard.scss';
import ClinicRegistration from 'pages/ClinicRegistration';
import MedicalRecords from 'pages/MedicalRecords';
import {CustomModal} from '../../components'; // Update the import statement
import CreateDoctor from 'pages/CreateDoctor';
import CreatePet from 'pages/CreatePet';
import PetProfile from 'pages/PetProfile';
import VetView from 'pages/VetView';

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();

  const { popupVisible, togglePopup } = useUserStore();
  const popupRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        togglePopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [togglePopup]);

  const clinic = useClinicStore((store) => store.clinic);
  const fetchClinicData = useClinicStore((store) => store.fetchClinicData);
  const fetchClinicAppointments = useClinicStore((store) => store.fetchClinicAppointments);
  const fetchGetFAQ = useUserStore((store) => store.getFAQ);
  const fetchGetPrivacyPolicy = useUserStore((store) => store.getPolicy);

  // useEffect(() => {
  //   fetchClinicData();
  //   fetchGetFAQ();
  //   fetchGetPrivacyPolicy();
  //   fetchClinicAppointments();
  // }, []);

  // useEffect(() => {
  //   const { location, license, registration, created_at, profile } = clinic;
  
  //   if (
  //     location === '' ||
  //     license === '' ||
  //     profile === null ||
  //     !profile?.name ||
  //     !profile?.phone
  //   ) {
  //     navigate('/clinic-registration');
  //   }
  // }, [location, navigate ]);
  const [clinicDataFetched, setClinicDataFetched] = useState(false);

  const fetchData = useCallback(async () => {
    await fetchClinicData();
    fetchGetFAQ();
    fetchGetPrivacyPolicy();
    fetchClinicAppointments();
    // setClinicDataFetched(true);
  }, [fetchClinicData, fetchGetFAQ, fetchGetPrivacyPolicy, fetchClinicAppointments]);


  useEffect(() => {
    if (clinicDataFetched) return;
    fetchData();
  }, [clinicDataFetched, fetchData]);

  useEffect(() => {
    if(!clinicDataFetched) return;
    const { location: clinicLocation, license, registration, created_at, profile } = clinic;

    if (
      clinicLocation === '' ||
      license === '' ||
      profile === null ||
      !profile?.name ||
      !profile?.phone
    ) {
      navigate('/clinic-registration');
    }
  }, [navigate, clinicDataFetched]);


  return (
    <>
      <Header />
      <Container withHeader={true} >
        <>
          {popupVisible && (
            <div ref={popupRef}>
              <ProfilePopUp />
            </div>
          )}
        </>
        <Routes>
          
          <Route path="/records" element={<MedicalRecords />} />
          <Route path="/create-pet" element={<CreatePet />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/clinic-registration" element={<ClinicRegistration />} />
          <Route path="/create-vet" element={<CreateDoctor />} />
          <Route path="/edit-vet/:id" element={<EditDoctor />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/pet-profile/:petId" element={<PetProfile />} />
          {/* other routes */}
          <Route
            path="/*"
            element={<Overview />}
          />
        </Routes>
      </Container>
      <CustomModal /> {/* Render the custom modal component */}
    </>
  );
}
