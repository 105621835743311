import Kalend, { CalendarView, OnEventDragFinish } from 'kalend';
import React, { useEffect, useRef, useState } from 'react';
import { useClinicStore, useAppointmentsStore } from '../../store';
import 'kalend/dist/styles/index.css';
import {
  AppointmentModalView,
  AppointmentModalUpdate,
} from '../AppointmentModal';
import Select from 'react-select';
import './CalendarStyles.scss';
import { ClassNames } from '@emotion/react';
import moment from 'moment';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
interface Event {
  id: number;
  startAt: string;
  endAt: string;
  // timezoneStartAt: string;
  summary: string;
  color: string;
  calendarID: string;
}

function CalendComponent(props: any) {
  const { appointments } = useClinicStore((store) => store.clinic);
  const openModal = useAppointmentsStore((state) => state.openModal); // access openModal function
  const [selectedVet, setSelectedVet] = useState('');
  const [vets, setVets] = useState<String[]>([]);
  const [demoEvents, setDemoEvents] = useState<Event[]>([]);
  const { veterinarians } = useClinicStore((store) => store.clinic);

  console.log(demoEvents);

  const fetchClinicAppointments = useClinicStore(
    (store) => store.fetchClinicAppointments
  );
  useEffect(() => {
    fetchClinicAppointments();
  }, []);

  const [timezone, setTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    if (appointments) {
      const vetIds = Array.from(
        new Set(
          [...appointments.upcoming, ...appointments.history].map(
            (appointment) => appointment.vetId.toString()
          )
        )
      );
      const events = [...appointments.upcoming, ...appointments.history]
        .filter(
          (appointment) =>
            appointment.status !== 'CANCELED' &&
            appointment.status !== 'COMPLETED' &&
            (!selectedVet || appointment.vetId.toString() === selectedVet)
        )
        .map((appointment) => ({
        
          startAt: moment(appointment.start_time).toISOString(),
          endAt: moment(appointment.end_time).toISOString(),
          summary: appointment.purpose || appointment.description,
          color: 'blue',
          calendarID: 'work', // Replace with logic to determine calendarID
          ...appointment,
        }));
      setVets(vetIds);
      for (let event of events) {
        const vet = veterinarians.find((vet) => vet.id === event.vetId);
        if (vet) {
          event.startAt = moment(event.startAt)
            .utcOffset(vet.time_zone / 60)
            .toISOString();
          event.endAt = moment(event.endAt)
            .utcOffset(vet.time_zone / 60)
            .toISOString();
        }
      }
      const newEvents = events.map((event) => ({
        ...event,
        summary: event.purpose || event.description || '',
      }));
      setDemoEvents(newEvents);

      // Check if the selectedVet is still in the new vetIds
      if (!vetIds.includes(selectedVet)) {
        setSelectedVet(''); // If not, reset the selectedVet
      }
    }
  }, [appointments, selectedVet]);

  const onNewEventClick = (data: any) => {
    openModal('create', data); // open the modal on new event click
  };

  const onEventClick = (data: any) => {
    openModal('view', data); // open the modal on event click
  };

  const onEventDragFinish = (prev: any, current: any, data: any) => {
    setDemoEvents(data);
  };

  const options = vets.map((vetId) => {
    const vetName = veterinarians.find((vet) => vet.id === Number(vetId))
      ?.profile.name;
    return {
      value: vetId,
      label: vetName || vetId,
    };
  });

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const copyVetLink = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <>
      <div className='selectVets-container'>
        <Select
          className='selectVet'
          value={options.find((option) => option.value === selectedVet)}
          onChange={(selectedOption) =>
            setSelectedVet(
              selectedOption ? selectedOption.value.toString() : ''
            )
          }
          options={options}
          placeholder='All Doctors'
          styles={{
            menu: (provided) => ({
              ...provided,
              backgroundColor: 'white',
              zIndex: 1,
            }),
            option: (provided, state) => ({
              ...provided,
              color: 'black',
              backgroundColor: state.isSelected
                ? 'rgba(222, 224, 234, 0.5)'
                : provided.backgroundColor,
            }),
            menuPortal: (base) => ({
              ...base,

              zIndex: 2999,
            }),
          }}
          menuPortalTarget={document.body}
        />

        {selectedVet && (
          <Button
            sx={{
              width: 'max-content'
            }}
            onClick={() => copyVetLink()}
          >
            Copy access link
          </Button>
        )}
      </div>
      <textarea
        ref={textAreaRef}
        value={`${process.env.REACT_APP_CLIENT_URL}/appointments/vet-view?vetId=${selectedVet}`}
        style={{ position: 'absolute', left: '-9999px' }}
        readOnly
      />
      <Kalend
        kalendRef={props.kalendRef}
        onNewEventClick={onNewEventClick}
        initialView={CalendarView.WEEK}
        disabledViews={[]}
        onEventClick={onEventClick}
        events={demoEvents}
        initialDate={new Date().toISOString()}
        hourHeight={60}
        focusHour={12}
        timezone={timezone}
        onEventDragFinish={onEventDragFinish}
        onStateChange={props.onStateChange}
        selectedView={props.selectedView}
        showTimeLine={true}
        isDark={false}
        autoScroll={true}
      />
      <AppointmentModalView />
      <AppointmentModalUpdate />
    </>
  );
}

export default CalendComponent;
