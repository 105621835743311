import {PrimaryButton} from '../Buttons';

interface Props {
  text: string
  loader: boolean
  className?: string
}

export default function SubmitButton(props: Props) {
  return (
    <PrimaryButton
      type="submit"
      text={props.text}
      className={`form__button ${props.className ? props.className : ''}` }
      loader={props.loader}
    />
  );
}
