import { error } from '../../data';
import { useEffect, useState } from 'react';
import Input from '../Input';
import { type } from 'os';

interface Props {
  // eslint-disable-next-line no-unused-vars
  name: string;
  setText: (text: string) => void;
  isEmpty: boolean;
  value?: string;
  isExperience?: boolean;
  placeholder?: string;
  isPhone?: boolean;
  primaryColor?: boolean;
  additionalClass?: string;
}

export default function TextInput(props: Props) {
  const [text, setText] = useState<string>('');
  const [textError, setTextError] = useState<string | null>(null);

  useEffect(() => {
    if (props.isEmpty) {
      if (!text.trim()) setTextError(error.required);
    }
  }, [props.isEmpty, text]);

  useEffect(() => {
    const phoneRegex = /^\d{5,15}$/;

    if (props.isExperience) {
      if (isNaN(Number(text))) {
        setTextError(error.experience);
      } else {
        setTextError(null);
      }
    } else if (props.isPhone) {
      if (!phoneRegex.test(text)) {
        setTextError(error.phone);
      } else {
        setTextError(null);
      }
    }
  }, [props.isPhone, props.isExperience, text]);

  const handlerTextBlur = async (text: string) => {
    if (!text.trim()) {
      setTextError(error.required);
    } else {
      setText(text);
      props.setText(text);
      setTextError(null);
    }
  };

  return (
    <Input
      className={
        'form__input' +
        (props.primaryColor ? ' primaryColor' : ' ') +
        ' ' +
        props.additionalClass
      }
      type='text'
      placeholder={props.placeholder || ''}
      error={textError}
      value={text || props.value || ''}
      label={props.name}
      onBlur={handlerTextBlur}
    />
  );
}
