import React, { useState, useRef, ChangeEvent } from 'react';
import moment from 'moment';
import './CreateDoctor.scss';
import SimpleForm from 'components/Form/SimpleForm';
import SubmitButton from 'components/Form/SubmitButton';
import TextInput from 'components/Form/TextInput';
import EmailInput from 'components/Form/EmailInput';
import { useClinicStore, useUserStore } from '../../store';
import { useNavigate } from 'react-router-dom';
import ImageUploader from 'components/ImageUpload';
import { fetchVetRegister } from 'store/api';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  SelectChangeEvent,
} from '@mui/material';
import { toast } from 'react-toastify';

// Define the options outside the component to avoid re-rendering
const animals = [
  'All',
  'Dogs',
  'Cats',
  'Rabbits',
  'Hamsters',
  'Parrots',
  'Turtles',
  'Snakes',
  'Lizards',
  'Fish',
  'Other',
];

const purposes = [
  'All',
  'Wellness check-up',
  'Vaccinations',
  'Sick visit',
  'Injury or trauma',
  'Surgery consultation',
  'Dental care',
  'Parasite control',
  'Behavioral issues',
  'Nutritional counseling',
  'Other',
];

export default function CreateDoctor() {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [about, setAbout] = useState('');
  const [speciality, setSpeciality] = useState('');
  const [associations, setAssociations] = useState('');
  const [title, setTitle] = useState('');
  const [experience, setExperience] = useState('');
  const [selectedAnimals, setSelectedAnimals] = useState<string[]>([]);
  const [selectedPurposes, setSelectedPurposes] = useState<string[]>([]);
  const [imageURL, setImageURL] = useState('');
  const [animalsError, setAnimalsError] = useState(false);
  const [purposesError, setPurposesError] = useState(false);

  const fetchClinicData = useClinicStore((state) => state.fetchClinicData);
  const fetchClinicAppointments = useClinicStore((state) => state.fetchClinicAppointments);
  const openModal = useUserStore((state) => state.openModal);

  const triggerFileSelect = () => {
    console.log('triggerFileSelect');
    console.log(inputRef.current);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const validateAnimals = () => {
    if (selectedAnimals.length === 0) {
      setAnimalsError(true);
      return true;
    } else {
      setAnimalsError(false);
      return false;
    }
  };

  const validatePurposes = () => {
    if (selectedPurposes.length === 0) {
      setPurposesError(true);
      return true;
    } else {
      setPurposesError(false);
      return false;
    }
  };

  const handleSubmit = async () => {
    const isAnimalsError = validateAnimals();
    const isPurposesError = validatePurposes();

    if (
      isAnimalsError ||
      isPurposesError ||
      selectedAnimals.length === 0 ||
      selectedPurposes.length === 0
    ) {
      return;
    }

    const vetData = {
      email,
      speciality,
      name,
      title,
      experience: experience
        ? moment().subtract(Number(experience), 'years').toISOString()
        : '',
      associations,
      about: about,
      avatar: imageURL,
      animals: selectedAnimals,
      purposes: selectedPurposes,
    };

    try {
      await fetchVetRegister(vetData);
      await fetchClinicData();
      await fetchClinicAppointments();
      openModal(
        'success',
        'Congratulations!',
        'Doctor’s account successfully saved!',
        null
      );
      navigate('/overview');
    } catch (error) {
      toast.error('Some error occured, please check your data', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'light',
        toastId: 'uniqueId3',
      });
      console.error('Failed to update clinic info: ', error);
    }
  };

  // Then in your change handlers:

  const handleAnimalChange = (event: SelectChangeEvent<string[]>) => {
    const values = event.target.value as string[];
    if (values.includes('All')) {
      setSelectedAnimals(animals.filter((animal) => animal !== 'All'));
    } else {
      setSelectedAnimals(values);
    }
  };

  const handlePurposeChange = (event: SelectChangeEvent<string[]>) => {
    const values = event.target.value as string[];
    if (values.includes('All')) {
      setSelectedPurposes(purposes.filter((purpose) => purpose !== 'All'));
    } else {
      setSelectedPurposes(values);
    }
  };

  return (
    <div className='vet-registration'>
      <SimpleForm
        submitHandler={handleSubmit}
        title='Create doctor’s account'
        bordered={'orange'}
      >
        <div className='simple-form__header'>
          <div className='simple-form__title'>{'Create doctor’s account'}</div>
          <SubmitButton
            text='Save'
            loader={false}
            className={`primary-button__orange
             primary-button__small primary-button__no-margin primary-button__xs`}
          />
        </div>
        <hr />
        <div className='vet-registration__upload-cont'>
          <ImageUploader
            setImageURL={setImageURL}
            inputRef={(node) => {
              inputRef.current = node;
            }}
            className='create-doctor-upload'
          />
          <div
            className='vet-registration__upload-cont__button'
            onClick={triggerFileSelect}
          >
            {imageURL && 'Set new photo'}
          </div>
        </div>
        <div className='vet-registration__grid'>
          <div className='vet-registration__item'>
            <TextInput
              name='Full name'
              setText={setName}
              primaryColor={true}
              isEmpty={false}
            />
          </div>
          <div className='vet-registration__item'>
            <EmailInput
              label='Email'
              setEmail={setEmail}
              primaryColor={true}
              isEmpty={false}
            />
          </div>
          <div className='vet-registration__item'>
            <TextInput
              name='Professional designation or title'
              primaryColor={true}
              setText={setTitle}
              isEmpty={false}
            />
          </div>
          <div className='vet-registration__item'>
            <TextInput
              name='Specific specialization or area of expertise'
              primaryColor={true}
              setText={setSpeciality}
              isEmpty={false}
            />
          </div>
          <div className='vet-registration__item'>
            <TextInput
              name='Membership of veterinary associations or organization'
              primaryColor={true}
              setText={setAssociations}
              isEmpty={false}
            />
          </div>
          <div className='vet-registration__item'>
            <TextInput
              name='Years of experience'
              setText={setExperience}
              primaryColor={true}
              isExperience={true}
              isEmpty={false}
            />
          </div>
          <div className='vet-registration__item'>
            <FormControl variant='filled' className='select-control' fullWidth>
              <InputLabel id='animals-select-label'>Animals</InputLabel>
              <Select
                labelId='animals-select-label'
                id='animals-select'
                multiple
                value={selectedAnimals}
                onBlur={validateAnimals}
                onChange={handleAnimalChange}
                error={animalsError} // add this line
              >
                {animals.map((animal, index) => (
                  <MenuItem key={index} value={animal}>
                    {animal}
                  </MenuItem>
                ))}
              </Select>
              {animalsError && selectedAnimals && (
                <div className='select-error'>
                  Please select at least one animal.
                </div>
              )}
            </FormControl>
          </div>
          <div className='vet-registration__item'>
            <FormControl variant='filled' className='select-control' fullWidth>
              <InputLabel id='purposes-select-label'>Purposes</InputLabel>
              <Select
                labelId='purposes-select-label'
                error={purposesError} // add this line
                id='purposes-select'
                multiple
                value={selectedPurposes}
                onBlur={validatePurposes}
                onChange={handlePurposeChange}
              >
                {purposes.map((purpose, index) => (
                  <MenuItem key={index} value={purpose}>
                    {purpose}
                  </MenuItem>
                ))}
              </Select>
              {purposesError && selectedPurposes && (
                <div className='select-error'>
                  Please select at least one purpose.
                </div>
              )}
            </FormControl>
          </div>
          <div></div>
        </div>
        <div className='vet-registration__item'>
          <label className='vet-registration__about-label'>About Veterinarian</label>
          <textarea
            className='input notes'
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
        </div>
      </SimpleForm>
    </div>
  );
}
