import {Form, PrimaryButton} from 'components';
import {Link, useNavigate} from 'react-router-dom';
import Email from 'assets/images/email.png';
import EmailInput from 'components/Form/EmailInput';
import SubmitButton from 'components/Form/SubmitButton';
import {useAuthStore} from 'store';
import {useState} from 'react';
import { useOnboardingStore } from 'store/onboarding';

export default function VerifyEmail() {
  const navigate = useNavigate();
  
  const email = useOnboardingStore((store) => store.email);

  return (
    <div className="password-recovery-email">
        <img className="password-recovery__image" src={Email} alt=""/>
        <h3 className="password-recovery__title">Verify your email address</h3>
        <p className="password-recovery__subtitle">
            You’ve entered {email} as the email address for your account.
            Please verify this email. 
        </p>
    </div>
  );
}
