import { Container } from 'components';
import Header from 'components/Header';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function VerifyEmail() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice =
      /iphone|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
    setIsMobile(isMobileDevice);

    if (isMobileDevice) {
      const appUrl = 'furrmobile://WS';
      window.location.assign(appUrl);
    }
  }, []);

  if (isMobile) {
    return (
      <div>
        <h1>Email Verified. Redirecting...</h1>
      </div>
    );
  } else {
    return (
      <>
      <Header/>
      <Container>
        <div className='welcome-page'>
          <div className='welcome-page__inner'>
            <h3 className='welcome-page__title'>
              Email Verified.
            </h3>
          </div>
        </div>
      </Container>
    </>
    );
  }
}
