import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { CustomButton } from 'components/Buttons';
import './AppointmentModalView.scss';
import { useAppointmentsStore, useClinicStore, useUserStore } from '../../store';  
import { ReactComponent as Cat} from 'assets/images/appointments/cat.svg';
import { ReactComponent as Calendar} from 'assets/images/appointments/calendar.svg';
import { ReactComponent as Clock} from 'assets/images/appointments/clock.svg';
import { ReactComponent as HourGlass} from 'assets/images/appointments/hourglass.svg';
import { ReactComponent as Person} from 'assets/images/appointments/user.svg';
import { ReactComponent as Cross} from 'assets/images/x.svg';
import { useState } from 'react';
import Spinner from 'components/Spinner';


export default function AppointmentModalView() {

  const { veterinarians } = useClinicStore(state => state.clinic);
  const modalOpen = useAppointmentsStore(state => state.modalOpen);
  const closeModal = useAppointmentsStore(state => state.closeModal);
  const modalData = useAppointmentsStore(state => state.modalData);
  const completeAppointment = useAppointmentsStore(state => state.completeAppointment);
  const openModal = useUserStore((state) => state.openModal);
  const modalStatus = useAppointmentsStore(state => state.modalStatus);
  const openEditModal = useAppointmentsStore(state => state.openModal); // access openModal function
 
  const formattedDate = modalData ?
    moment.utc(modalData.start_time).local().format('Do MMMM YYYY') :
    '';
  const formattedStartAt = modalData ?
    moment.utc(modalData.start_time).local().format('H:mm') :
    '';
  const formattedEndAt = modalData ?
    moment.utc(modalData.end_time).local().format('H:mm')
    : '';
  
  const defaultValue = modalData ? `${formattedStartAt} - ${formattedEndAt}` : '';
  const vet = veterinarians.find(vet => vet?.id === modalData?.vetId);

  const handleComplete = async (id: number) => {
    try {
      await completeAppointment(id);
      closeModal();
    } catch (err) {
      console.log('complete appointment error');
    }
  };

  return (
    <Dialog
      open={modalData  && modalStatus === 'view' && modalOpen ? true : false} 
      onClose={closeModal} 
      className="appointment-modal"
    >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          className="appointment-modal__title__close"
        >
        <Cross/>
         
        </IconButton>
      <DialogContent>
        
      <DialogTitle className="appointment-modal__title">
        
        <div className="appointment-modal__title__text">
          {modalData?.summary}
        </div>

        <div className="appointment-modal__title__doctor">
          <img 
            src={vet?.profile?.avatar || 'https://www.danchankoinc.com/wp-content/uploads/2017/01/nophoto.png'} 
            alt={vet?.profile?.name} 
            className="appointment-modal__title__doctor__avatar" />
          <div className="appointment-modal__title__doctor__name">
            with {vet?.profile?.name}
          </div>             
        </div>

      </DialogTitle>
      <div className="appointment-modal__divider" />
      <DialogContent>
        <div className="appointment-modal__content">
          <div className="appointment-modal__content__item">
            <div className="appointment-modal__content__item__half">
              <div className="appointment-modal__content__item__text-block">
                <Person className="appointment-modal__content__item__icon" />
                <div className="appointment-modal__content__item__text-cont">
                  <div className="appointment-modal__content__item__text">
                    {'Specialist'}
                    <div className="appointment-modal__content__item__text__secondary">
                      {vet?.speciality}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="appointment-modal__content__item__half">
        
              <div className="appointment-modal__content__item__text-block">
              
                <HourGlass className="appointment-modal__content__item__icon" />
                <div className="appointment-modal__content__item__text-cont">
                  <div className="appointment-modal__content__item__text">
                    {'Duration'}
                    <div className="appointment-modal__content__item__text__secondary">
                      {'1 hour'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="appointment-modal__content__item">
            <div className="appointment-modal__content__item__half">
              <div className="appointment-modal__content__item__text-block">
                <Calendar className="appointment-modal__content__item__icon" />
                <div className="appointment-modal__content__item__text-cont"> 
                  <div className="appointment-modal__content__item__text">
                    {'Date'}
                    <div className="appointment-modal__content__item__text__secondary">
                      {formattedDate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="appointment-modal__content__item__half">
              <div className="appointment-modal__content__item__text-block">
                <Clock className="appointment-modal__content__item__icon" />
                <div className="appointment-modal__content__item__text-cont"> 
                  <div className="appointment-modal__content__item__text">
                    {'Time'}
                    <div className="appointment-modal__content__item__text__secondary">
                      {defaultValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="appointment-modal__content__additional-info">
            <div className="appointment-modal__content__item">
              <div className="appointment-modal__content__item__text-block">
                <Cat className="appointment-modal__content__item__icon" />
                <div className="appointment-modal__content__item__text-cont">
                  <div className="appointment-modal__content__item__text">
                    {'Patient'}
                    <div className="appointment-modal__content__item__text__secondary">
                      {modalData?.pet?.profile?.breed + ' ' + modalData?.pet?.profile?.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="appointment-modal__content__item">
              <div className="appointment-modal__content__item__text-block">
               
                <div className="appointment-modal__content__item__text-cont"> 
                  <div className="appointment-modal__content__item__text">
                    {'ID'}
                    <div className="appointment-modal__content__item__text__secondary">
                      {'#' + modalData?.pet?.profile.id}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="appointment-modal__content__item">
              <div className="appointment-modal__content__item__text-block">
               
                <div className="appointment-modal__content__item__text-cont"> 
                  <div className="appointment-modal__content__item__text">
                    {'Owner'}
                    <div className="appointment-modal__content__item__text__secondary">
                      { modalData?.pet?.owners[0]?.profile?.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </DialogContent>

      <DialogActions className="appointment-modal__buttons">

      {modalData ? (
          <>
            {moment.utc().isBefore(modalData.start_time) ? (
              <>
              <CustomButton
                text="Reschedule"
                classes={`
                  custom-button__small
                  custom-button__no-hover-bordered 
                  appointment-modal__button
                `}
                variant="outline"
                onClick={(e) => {
                  openEditModal('edit', modalData);
                }}
              />
              <CustomButton
                text="Delete"
                classes={`
                custom-button__thin
                custom-button__no-margin 
                custom-button__no-hover
                custom-button__delete-cancel
                custom-button__delete-ghost
                appointment-modal__button
                `}
                type="submit"
                onClick={(e) => {
                  openModal('warning',
                    'Are you sure?',
                    'You want to delete this appointment?',
                    'deleteAppointment',
                    modalData
                  );
                }}
              />
              </>
            ) : (
              <CustomButton
                text="Complete"
                style={{margin : '0 auto'}}
                parentClasses='custom-button__self-center'
                classes={`
                  custom-button__small
                  custom-button__no-hover-bordered
                  appointment-modal__button
                `}
                variant="outline"
                onClick={(e) => {
                  handleComplete(modalData?.id);
                }}
            />
            )}

          </>
        ) : null}
   
      </DialogActions>
      </DialogContent>

    </Dialog>
  );
}
