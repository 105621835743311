import { InputAdornment, TextField, Button } from '@mui/material';
import { useState, FocusEvent, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useClinicStore } from 'store';
import { useNavigate } from 'react-router-dom';
import './MedicalRecords.scss';
import { PetDB } from 'pages/PetProfile/PetProfile';

import dog from '../../assets/images/dog.png';
import cat from '../../assets/images/cat.png';
import lizard from '../../assets/images/lizard.png';
import rabbit from '../../assets/images/rabbit.png';
import rooster from '../../assets/images/parrot.png';
import snake from '../../assets/images/snake.png';
import fish from '../../assets/images/fish.png';
import turtle from '../../assets/images/turtle.png';
import hamster from '../../assets/images/hamster.png';
import defaultImage from '../../assets/images/default.png';

const imageComponents = [
  { name: 'dog', source: dog },
  { name: 'cat', source: cat },
  { name: 'lizard', source: lizard },
  { name: 'rabbit', source: rabbit },
  { name: 'rooster', source: rooster },
  { name: 'snake', source: snake },
  { name: 'fish', source: fish },
  { name: 'turtle', source: turtle },
  { name: 'hamster', source: hamster },
  { name: 'no avatar', source: defaultImage },
];

const getAvatarSource = (avatar?: string) => {
  const image = imageComponents.find((image) => image.name === avatar);
  return image ? image.source : null;
};

export default function MedicalRecords() {
  const [images, setImages] = useState(imageComponents);
  const [petsFilter, setPetsFilter] = useState<PetDB[]>([]);

  const fetchClinicData = useClinicStore((state) => state.fetchClinicData);
  const fetchClinicAppointments = useClinicStore((state) => state.fetchClinicAppointments);

  const navigate = useNavigate();

  const { pets } = useClinicStore((store) => store.clinic);

  const getAvatarSource = (avatar: string) => {
    const image = images.find((image) => image.name === avatar);
    return image ? image.source : null;
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if(event.target.value) 
      setPetsFilter(petsFilter.filter(
        (pet) => {
          return pet.profile?.name.toLowerCase()
              .includes(event.target.value.toLowerCase().trim())
          || pet.profile?.id.toString()
              .includes(event.target.value.toLowerCase().trim());
        }
      ));
    else
      setPetsFilter(pets as unknown as PetDB[]);
  };

  const handleButtonClick = (id: number) => {
    navigate(`/pet-profile/${id}`);
  };

  useEffect(() => {
    fetchClinicData();
    fetchClinicAppointments();
  }, [fetchClinicData, fetchClinicAppointments]);

  useEffect(() => {
    setPetsFilter(pets as unknown as PetDB[]);
  }, [pets]);

  return (
    <div className="records">
      <div className="records__container">
        <div className="records__header">
          <div className="records__title">Medical records</div>
          <div className="records__search-container">
            <TextField
              className="records__search"
              variant="outlined"
              type="text"
              size="small"
              onChange={handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="records__Add-pet-container">
            <Button 
              className="records__Add-pet-button" 
              onClick={() => navigate('/create-pet')}
            >+ Add new Pet</Button>
          </div>
        </div>
        <div className="records__hl"></div>
        <div className="records__table-container">
          <table className="records__table">
            <thead className="records__table-head">
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>Created at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {petsFilter?.map((pet) => (
                <tr key={pet.id}>
                  <td className="records__table-first-col">
                    <img
                      src={
                        getAvatarSource(pet?.profile.avatar) || defaultImage
                      }
                      className="records__table-img"
                      alt={pet?.profile?.name}
                    />
                    <div className="records__table-text-container">
                      <div className="records__table-type-name">
                        <div className="records__table-type">
                          {pet?.profile?.breed}
                        </div>
                        <div className="records__table-name">
                          {pet?.profile?.name}
                        </div>
                      </div>
                      <div className="records__table-age">
                        {new Date(
                          pet?.created_at
                        ).toLocaleDateString()}
                      </div>
                    </div>
                  </td>
                  <td className="records__table-id">#{pet?.profile.id}</td>
                  <td className="records__table-date">
                    {new Date(pet?.profile?.birthday).toLocaleDateString()}
                  </td>
                  <td className="records__table-button-column">
                    <div>
                      <Button
                        variant="outlined"
                        style={{
                          width: '100.71578979492188px',
                          height: '31px',
                          borderRadius: '22px',
                          marginTop: '-31px',
                        }}
                        onClick={() => handleButtonClick(pet.profile.id)}
                      >
                        Details
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
