import { Link } from 'react-router-dom';
import { useAuthStore, useUserStore, useClinicStore } from '../../store';
import { ReactComponent as Add } from 'assets/images/add.svg';
import { ReactComponent as ClinicPlaceholder} from 'assets/images/clinic-logo.svg';
import './ProfilePopUp.scss';

export default function ProfilePopUp() {
  const { user } = useAuthStore((store) => ({ user: store.user }));
  const { profile, veterinarians } = useClinicStore((store) => store.clinic);

  const { popupVisible, togglePopup } = useUserStore();

  return (
    <div className="ProfilePopUp">
      <Link to="/settings">
        <div className="ProfilePopUp__header" onClick={togglePopup}>
          <ClinicPlaceholder
            className="ProfilePopUp__header__avatar" />
          <div className="ProfilePopUp__header__text-container">
            <div className="ProfilePopUp__header__text-container__type-name">
              <div className="ProfilePopUp__header__text-container__name">{profile?.name}</div>
            </div>
            <div className="ProfilePopUp__header__text-container__email">{profile?.email}</div>
          </div>
        </div>
        <hr className="ProfilePopUp__divider" />
      </Link>
      <div className="ProfilePopUp__actions">
        <div className="ProfilePopUp__actions__header">Doctor`s profile</div>

        <div className="ProfilePopUp__actions__vets-cont">
          {veterinarians &&
          veterinarians.map((vet: any) => (
            <Link to={`/edit-vet/${vet.id}`} key={vet.id}>
              <div className="ProfilePopUp__actions__item" onClick={togglePopup}>
                <img 
                  src={vet?.profile?.avatar || 'https://www.danchankoinc.com/wp-content/uploads/2017/01/nophoto.png'} 
                  alt={vet?.profile?.name} 
                  className="ProfilePopUp__actions__item__avatar" />
                <div className="ProfilePopUp__actions__item__name">
                  {vet?.profile?.name}
                </div>
              </div>
            </Link>
          ))}
        </div>

        <Link to="/create-vet">
          <div className="ProfilePopUp__actions__add">
            <Add />
            <div className="ProfilePopUp__actions__add__text">Add doctor</div>
          </div>
        </Link>
      </div>
    </div>
  );
}
