import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';
import { ReactComponent as User } from 'assets/images/appointments/userNoCircle.svg';
import './AppointmentModalUpdate.scss';
import { useAppointmentsStore, useClinicStore } from '../../store';  

import { ReactComponent as Cross} from 'assets/images/x.svg';
import { 
  fetchFreeSlotsByDate,
  fetchGetPetsByOwner,
  fetchCreateAppointment,
  fetchUpdateAppointment
   } from 'store/api';


type Slot = {
  id: number;
  start_time: string;
  end_time: string;
  duration: number;
  vetId: number;
  status: string;
  created_at: string;
};

type SlotsArray = Slot[];

type OwnerResponse = {
  userId: number;
};

type Pet = {
  id: number;
  is_archived: boolean;
  default_logo: string;
  microchipId: string | null;
  created_at: string;
  owners: OwnerResponse[] ;
  profile: {
    id: number;
    color: string;
    breed: string;
    name: string;
    weight: number;
    birthday: string;
    notes: string;
    avatar: string;
    created_at: string;
  };
};


export default function AppointmentModalUpdate() {
  const modalData = useAppointmentsStore(state => state.modalData);
  const fetchClinicAppointments = useClinicStore((store) => store.fetchClinicAppointments);

  const formattedDate = modalData ?
  moment(modalData?.startAt).format() :
  moment(modalData?.day).startOf('day').format();

  const formattedStartAt = modalData ?
  moment(modalData.startAt).format('h:mm') :
  '';
const formattedEndAt = modalData ?
  moment(modalData.endAt).format('h:mm')
  : '';

  const { veterinarians } = useClinicStore(state => state.clinic);
  const modalOpen = useAppointmentsStore(state => state.modalOpen);
  const closeModal = useAppointmentsStore(state => state.closeModal);

  const modalStatus = useAppointmentsStore(state => state.modalStatus);
  const [menuItems, setMenuItems] = useState<JSX.Element[]>([]);
  const [ownersPets, setOwnersPets] = useState<Pet[]>([]);
  const [selectedTime, setSelectedTime] = useState(
    modalData ? `${formattedStartAt} - ${formattedEndAt}` : ''
    );

  const [email, setEmail] = useState(modalData?.pet?.owners[0]?.profile.email || '');

// const handleEmailChange = (event) => {
//   setEmail(event.target.value);
// };
  const [selectedPet, setSelectedPet] = useState(modalData?.pet?.profile.name || '');
  const [description, setDescription] = useState(modalData?.purpose || 
    modalData?.description || '');
  const [defaultValue, setDefaultValue] =
   useState(modalData ? `${formattedStartAt} - ${formattedEndAt}` : ''); 

   const [selectedPerson, setSelectedPerson] = useState(() => {
    if (modalData?.vetId) {
      const vet = veterinarians.find(vet => vet?.id === modalData?.vetId);
      if (vet) return vet?.id;
    }
    return '';
  });

  useEffect(() => {
    if (modalData && modalData.pet) {
      setEmail(modalData.pet?.owners[0]?.profile.email);
      setSelectedPerson(modalData.vetId);
      setDescription(modalData.purpose || modalData.description || '');
      setSelectedPet(modalData.pet?.profile.name);

      const formattedStartAt =
        moment(modalData.startAt).format('H:mm');
      const formattedEndAt =
        moment(modalData.endAt).format('H:mm');

      setDefaultValue(`${formattedStartAt} - ${formattedEndAt}`);
    }
  }, [modalData]);

  const [serverResponse, setServerResponse] = useState<SlotsArray>([]);

  const handleClose = () => {
    setSelectedTime('');
    setEmail('');
    setSelectedPet('');
    setSelectedPerson('');
    closeModal();
  };

   const handleSave = () => {
    // fetch pet ID from selectedPet
    const pet = ownersPets?.find(pet => pet.profile.name === selectedPet);
    const petId = pet ? pet.id : null;
   
  
    // find the slot ID from selectedTime
    const selectedSlotLabel = selectedTime;
    const selectedSlot = serverResponse.find(slot => {
      const startTime = moment.utc(slot.start_time).local().format('H:mm');
      const endTime = moment.utc(slot.end_time).local().format('H:mm');
      const label = `${startTime} - ${endTime}`;
      return label === selectedSlotLabel;
    });
  
    const vetId = Number(selectedPerson);

    fetchCreateAppointment({
      userId: pet?.owners[0].userId,
      vetId,
      startDate: new Date(`${selectedSlot?.start_time}`),
      endDate: new Date(`${selectedSlot?.end_time}`),
      petId,
      description
    })
    .then(() => {
      fetchClinicAppointments();
      // Add your success logic here
      // Maybe you want to close the modal and refresh the list of appointments
    })
    .catch((error) => {
      // Handle any errors that occur during the creation of appointment
      console.error('Failed to create appointment: ', error);
    });
  
    // Logic to handle save action
    handleClose();
  };
  
  const handleUpdate = () => {
    // fetch pet ID from selectedPet
    const pet = ownersPets.find(pet => pet.profile.name === selectedPet);
    const petId = pet ? pet.id : null;
  
    // find the slot ID from selectedTime
    const selectedSlotLabel = selectedTime;
    const selectedSlot = serverResponse.find(slot => {
      const startTime = moment.utc(slot.start_time).local().format('H:mm');
      const endTime = moment.utc(slot.end_time).local().format('H:mm');
      const label = `${startTime} - ${endTime}`;
      return label === selectedSlotLabel;
    });
  
    // assuming the user ID and vet ID are known (hard-coded here as 0)
    const vetId = Number(selectedPerson);
  
    fetchUpdateAppointment({
      id: modalData?.id,
      startDate: new Date(`${selectedSlot?.start_time}`),
      endDate: new Date(`${selectedSlot?.end_time}`),
      status: 'PENDING',
      pet_id: petId,
      vet_id: vetId,
      description: description
    })
    .then(() => {
      fetchClinicAppointments();
      // Add your success logic here
      // Maybe you want to close the modal and refresh the list of appointments
    })
    .catch((error) => {
      // Handle any errors that occur during the creation of appointment
      console.error('Failed to create appointment: ', error);
    });
  
    // Logic to handle save action
    handleClose();
  };

  

  



  async function fetchSlotsByVetId(date: string, vetId: number | string): Promise<SlotsArray> {
    try {
      const response = await fetchFreeSlotsByDate(date, `${vetId}`);
      if (!Array.isArray(response.data)) {
        console.error('Response is not an array: ', response.data);
        return [];
      }
      const serverResponse: SlotsArray = response.data as unknown as SlotsArray;
      return serverResponse;
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Failed to fetch slots: ', error);
      return [];
    }
  }
  
  useEffect(() => {
    if (selectedPerson) {
      fetchSlotsByVetId(formattedDate, selectedPerson)
        .then(res => {
          setServerResponse(res); // Updating server response state
          setDefaultValue('');  // set default value state to empty
          setSelectedTime(''); // set selected time state to empty
          const items = res.map((slot) => { 
            const startTime = moment.utc(slot.start_time).local().format('H:mm');
            const endTime = moment.utc(slot.end_time).local().format('H:mm');
            const label = `${startTime} - ${endTime}`;
            return <MenuItem key={slot.id} sx={{
                color: '#979696',
                fontSize: 14,
                '&:hover': {
                  backgroundColor: '#EAEAEA',
                },
                }}
                value={label}
              >{label}</MenuItem>;
          });
          setMenuItems(items); // set the menu items state
        });
    }
  }, [selectedPerson]);

  const handleEmailBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if(!email) return;
    const pets = await fetchGetPetsByOwner({email});
    setOwnersPets(pets.data);
  };

  async function fetchGetPetsByOwnerApi(email: string): Promise<Pet[]>{
    try {
      const response = await fetchGetPetsByOwner({email});
      return response.data as unknown as Pet[];
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error('Failed to fetch pets: ', error);
      return [];
    }
  }

  useEffect(() => {
    fetchGetPetsByOwnerApi(email).then(res => {
      setOwnersPets(res);
    });
  }, [email]);

  console.log(moment(modalData?.startAt).format('Do MMMM YYYY'));
  console.log(formattedDate);

  return (
    <Dialog
      open={
        modalData && 
        (modalStatus === 'create' || modalStatus === 'edit')
        && modalOpen ? true : false
      }    
      style={{
        width: '981px ',
        height: '480px',
        overflow: 'hidden', 
        margin: 'auto', 
        }}
      onClose={handleClose
      } className="appointment-modal-update">
    
      <div className="appointment-modal-update__title__close">
            <IconButton
            aria-label="close"
            onClick={handleClose}
            className="appointment-modal-update__title__close"
            >
              <Cross/>
            </IconButton>
          </div>
        <div className="appointment-modal-update__form">
        
          <div className="appointment-modal-update__input">
            <TextField 
              placeholder='Add title'
              defaultValue={modalData?.purpose || modalData?.description || ''} 
              onChange={(e) => setDescription(e.target.value)}
              variant="filled"
              className='appointment-modal-update__input__select'
              InputProps={{ 
                className: 'appointment-modal-update__input__select__title',
                id: 'filled-multiline-static', 
                style: { 
                  backgroundColor: 'white',
                  marginLeft: '2px',
                },
              }}
              fullWidth />
          </div> 
          <div className="appointment-modal-update__input">
          
            <TextField
              select
              fullWidth
              variant="filled"
              defaultValue={modalData?.vetId || 0}
              value={selectedPerson || modalData?.vetId || 0}
              className={'appointment-modal-update__input__select'}
              InputProps={{ 
                className: 'appointment-modal-update__input__select__input',
                startAdornment: (
                  <PersonIcon
                      className="appointment-modal-update__icon"
                    />
                ),
                style: { 
                  backgroundColor: 'white !important', 
                  height: '28px  ',
                },
              }}
              sx={{
                '& .appointment-modal-update__input__select__input': {
                  '& .css-1k19kol-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input': {
                    color: selectedPerson ? '#08182F' : '#979696',
                    fontWeight: selectedPerson ? 500 : 400,
                  }
                }
              }}
              onChange={(e) => setSelectedPerson(e.target.value)}
            >
              <MenuItem value={0} sx={{
                  color: '#979696',
                  fontSize: 14,
                  '&:hover': {
                    backgroundColor: '#EAEAEA',
                  },
                  }}
                  disabled> 
                  Add doctor
              </MenuItem>

              {veterinarians?.map((vet) => (
                <MenuItem key={vet?.id} value={vet?.id} sx={{
                  color: '#979696',
                  fontSize: 14,
                  '&:hover': {
                    backgroundColor: '#EAEAEA',
                  },
                  }}> 
                  {vet?.profile?.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

        <div className="appointment-modal-update__input">
          <div className="appointment-modal-update__input__cont">
            <AccessTimeIcon className="appointment-modal-update__icon" />
            <div 
              className="appointment-modal-update__input__date">
                {modalData?.startAt ? moment(modalData.startAt).format('Do MMMM YYYY') : ''}
            </div>
          </div>
          <Select 
            value={selectedTime || defaultValue}
            defaultValue={defaultValue}
            className={`appointment-modal-update__input__selector
                        appointment-modal-update__input__selector__dropdown`}
            onChange={(e) => setSelectedTime(e.target.value)}
            sx={{ 
              width: 125,  
              '& .MuiSelect-root': { fontSize: 14, overflow: 'unset', whiteSpace: 'normal' },
              '& .MuiMenuItem-root': { fontSize: 14 },
              '& .MuiMenuItem-root.Mui-selected': { backgroundColor: 'grey', fontSize: 14 },
            }}
            inputProps={{
              style: {
                width: 125, 
                color: '#2F5EA0', 
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  width: 125,
                },
              },
            }}
          > 
              {defaultValue ? 
                <MenuItem value={defaultValue} 
                sx={{
                  color: '#2F5EA0',
                  fontSize: 14,
                }}
                >{defaultValue}</MenuItem> : 
                'Select time slot'}

              {menuItems} 
            </Select>
          </div>

            <div className="appointment-modal-update__input">
            <TextField 
              placeholder='Add owner'
              value={email || modalData?.pet?.owners[0]?.profile.email || ''}
              defaultValue={modalData?.pet?.owners[0]?.profile.email} 
              variant="filled"
              onChange={(e) => {
                setEmail(e.target.value);
                setSelectedPet('');
              }}
              onBlur={handleEmailBlur} // add this line
              className='appointment-modal-update__input__select'
              InputProps={{ 
                className: 'appointment-modal-update__input__select__input',
                startAdornment: (
                  <User className="appointment-modal-update__icon" />
                ),
                style: { 
                  backgroundColor: 'white', 
                  height: '28px', 
                },
              }} 
              fullWidth 
            />
             </div>
          <div className="appointment-modal-update__input">
            {/* <PetsIcon
              className="appointment-modal-update__icon"
            /> */}
            <TextField
              select
              fullWidth
              variant="filled"
              defaultValue={modalData?.pet?.profile.name || 0}
              value={selectedPet || 0}
              disabled={!email}
              className='appointment-modal-update__input__select'
              onChange={(e) => setSelectedPet(e.target.value)}
              InputProps={{ 
                className: 'appointment-modal-update__input__select__input ',
                startAdornment: (
                  <PetsIcon className="appointment-modal-update__icon" />
                ),
                style: { 
                  backgroundColor: 'white',
                  height: '28px',
                },
              }} 
              sx={{
                '& .appointment-modal-update__input__select__input': {
                  '& .css-1k19kol-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input': {
                    color: selectedPet ? '#08182F' : '#979696',
                    fontWeight: selectedPet ? 500 : 400,
                  }
                }
              }}
            >
              <MenuItem value={0} sx={{
                  color: '#979696',
                  fontSize: 14,
                  '&:hover': {
                    backgroundColor: '#EAEAEA',
                  },
                  }}
                  disabled>
                  Add pet
                </MenuItem>

              {ownersPets?.map((pet) => (
                <MenuItem key={pet.id} value={pet.profile.name} sx={{
                  color: '#979696',
                  fontSize: 14,
                  '&:hover': {
                    backgroundColor: '#EAEAEA',
                  },
                  }}>
                  {pet.profile.name}
                </MenuItem>
              ))}
            </TextField>

          </div>

          <Button
          sx={{
            paddingX: '53px',
            paddingY: '14px',
            height: '42px',
            width: 'max-content',
            backgroundColor: '#FF974D',
            borderRadius: '30px',
            textTransform: 'none',
            fontSize: '13px',
            alignSelf: 'center',
            fontWeight: 500,
            opacity: !email || !selectedPet || !selectedTime || !selectedPerson || !description 
              ? '50%' 
              : '100%',
            pointerEvents: !email || !selectedPet || !selectedTime || !selectedPerson 
            || !description
              ? 'none'
              : 'all'
          }}
          className='appointment-modal-update__button'
          onClick={ 
            modalStatus === 'create' ? 
            handleSave 
            :
            handleUpdate
          } 
          variant="contained" color="primary">
            Save
        </Button>

        </div> 

    </Dialog>
  );
}
